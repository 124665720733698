import React from 'react'
import Image404 from '/static/images/ic-404.svg'
import DarkImage404 from '/static/images/darkMode/ic-404.svg'
import ErrorPageBase from './ErrorPageBase'
import { useUserAgentStore } from 'store/userAgentStore'

const Error404Page = () => {
  const userAgentStore = useUserAgentStore()
  const { isDarkMode } = userAgentStore
  const errorImage = isDarkMode ? <DarkImage404 /> : <Image404 />
  return <ErrorPageBase img={errorImage} description={'找不到網頁'} />
}

export default Error404Page
