// front-end ajax http request collection
import axios from 'axios'
import config from 'config'

const couponAPI = axios.create({
  baseURL: config.COUPON_API.SERVER_URL,
  timeout: config.COUPON_API.TIMEOUT,
  headers: {
    'HK01-Auth-Schema': 'CLIENT',
  },
})

function getCouponList(jwt, params = {}) {
  return couponAPI.request({
    url: `/api/client/v2.2/coupons`,
    method: 'GET',
    params: { isUseable: false, ...params },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

function getCouponStatusCount(jwt) {
  return couponAPI.request({
    url: `/api/client/v2.2/coupons/stats`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

function getPublicSkuList(isFeature, params) {
  return couponAPI.request({
    url: `/api/public/v2.2/skus`,
    method: 'GET',
    params: { isFeature, ...params },
    headers: {
      'HK01-Auth-Schema': 'PUBLIC',
    },
  })
}
// waiting backend new api for v2.3
function getSkuList(params) {
  return couponAPI.request({
    url: `/api/public/v2.2/skus`,
    method: 'GET',
    params: { isFeature: false, ...params },
    headers: {
      'HK01-Auth-Schema': 'PUBLIC',
    },
  })
}

function getSkus(params) {
  return couponAPI.request({
    url: `/api/public/v2.4/skus`,
    method: 'GET',
    params: { ...params },
    headers: {
      'HK01-Auth-Schema': 'PUBLIC',
    },
  })
}
function getSku(id, params) {
  return couponAPI.request({
    url: `/api/public/v2.2/skus/${id}`,
    method: 'GET',
    params,
    headers: {
      'HK01-Auth-Schema': 'PUBLIC',
    },
  })
}

function getCoupon(jwt, id) {
  return couponAPI.request({
    url: `/api/client/v2.2/coupons/${id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

function getRedeemProfile(jwt, skuId) {
  return couponAPI.request({
    url: `/api/client/v2.2/skus/${skuId}/redeemProfile`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

function redeemCoupon(jwt, skuId) {
  return couponAPI.request({
    url: `/api/client/v2.2/skus/${skuId}/redeem`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

function buyCoupon(jwt, skuId, data) {
  return couponAPI.request({
    url: `/api/client/v2.3/skus/${skuId}/redeem`,
    method: 'POST',
    data,
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}
function getTransactionDetail(jwt, transactionId) {
  return couponAPI.request({
    url: `/api/client/v2.3/transactions/${transactionId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}
function useCoupon(jwt, id, pin) {
  return couponAPI.request({
    url: `/api/client/v2.2/coupons/${id}`,
    method: 'POST',
    data: {
      redeemPin: pin,
    },
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
}

function getCategories() {
  return couponAPI.request({
    url: `/api/public/v2.2/categories`,
    method: 'GET',
    headers: {
      'HK01-Auth-Schema': 'PUBLIC',
    },
  })
}

export default {
  getCoupon,
  getCouponList,
  getRedeemProfile,
  getSku,
  getPublicSkuList,
  getSkuList,
  getSkus,
  redeemCoupon,
  buyCoupon,
  useCoupon,
  getCategories,
  getCouponStatusCount,
  getTransactionDetail,
}
