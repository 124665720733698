import 'moment-duration-format'
import React from 'react'
import styled from 'styled-components'

const padZero = (num) => (num < 10 ? `0${num}` : `${num}`)
const formatCountdown = (countdown) =>
  `${padZero(Math.floor(countdown / 60))}m : ${padZero(countdown % 60)}s`

const TapToUseCountdown = ({ timeout }) => {
  return (
    <Container>
      <Tip>優惠碼有效時間剩餘</Tip>
      <Time counting={timeout > 0}>{formatCountdown(timeout)}</Time>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background-color: ${(p) => p.theme.color.importantColor};
  color: ${(p) => p.theme.color.countdownColor};
  z-index: 3000;
`

const Tip = styled.div`
  font-size: 12px;
`

const Time = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 4px;
`

export default TapToUseCountdown
