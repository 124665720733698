module.exports = {
  BUGSNAG_API_KEY: '193d98db5be5bbff7206520fde3132fd',
  WEB_SERVER: {
    PORT: 3000,
    BASE_URL: 'http://localhost:3000',
    WEB_BASE_URL: 'http://localhost:3000',
  },
  SSO: {
    APP_ID: 'e2cfa7a8-cc51-40f7-ba2c-53493290bdad',
    WEB_APP_ID: '25b7c1da-e2a0-49f7-8e05-d1ee88234fdf',
    REDIRECT_TO_HOST: 'http://localhost:3000',
    REDIRECT_TO_WEB_HOST: 'http://localhost:3000',
    WEB_SSO_DOMAIN: 'https://staging-sso.01coupon.com/api/v1.0',
  },
  COUPON_API: {
    SERVER_URL: 'https://wallet.hktester.com/coupon-service/',
    TIMEOUT: 30000,
  },
  PUSHER: {
    KEY: '2d9fd43a98adadfc59d9',
    CLUSTER: 'ap1',
    FORCE_TLS: true,
    AUTH_ENDPOINT: 'https://wallet.hktester.com/pusher/auth',
  },
  TRACK: {
    PIWIK_APP_ENDPOINT: 'https://track.hktester.com/v2/piwik.php',
    GA_ID: 'UA-70981149-42',
    GA4_ID: 'G-39FE7WK8CV',
    APP_SITE_ID: '5',
    PIWIK_WEB_ENDPOINT: 'https://stg-track.01coupon.com/v2/piwik.php',
    WEB_SITE_ID: '61',
  },
  APP_INFO: {
    SCHEME: 'hk01uat',
    PACKAGE_NAME: 'com.hk01.news_app.debug',
  },
  FB_APPID: '1244227315689301',
  ONE_SIGNAL_APP_ID: '8964c7d4-3c88-41d8-9006-02ec3dec2281',
}
