import createMobxStoreCtx from './_createStore'

function createStore() {
  // note the use of this which refers to observable instance of the store
  return {
    isLoading: false,
    setLoading(bool) {
      this.isLoading = bool
    },
  }
}

const [LoadingStoreProvider, useLoadingStore] = createMobxStoreCtx(createStore)

export { LoadingStoreProvider, useLoadingStore }
