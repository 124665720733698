import React, { useContext } from 'react'
import { useLocalStore } from 'mobx-react-lite'

const ServiceSegmentContext = React.createContext(null)

export const ServiceSegmentProvider = ({ children, serviceSegment }) => {
  const store = useLocalStore(() => ({
    embedIn01FamilyApp: {
      app01webview: true,
      generalWeb: false,
    }[serviceSegment],
    serviceSegment,
  }))

  return (
    <ServiceSegmentContext.Provider value={store}>
      {children}
    </ServiceSegmentContext.Provider>
  )
}

export function useServiceSegmentStore() {
  const store = useContext(ServiceSegmentContext)
  if (!store) {
    throw new Error('You have forgot to use service segment context')
  }
  return store
}
