import getConfig from 'next/config'
import { path } from 'ramda'
import URI from 'urijs'

import config from 'config'
import { getWebJsSDK } from 'utils/sdkUtil'

/**
 * assume this function will only run in browser env!
 */
export async function loginOrGetToken({
  pathname,
  query = {},
  forceLogin = true,
  loginSource = 'normal-login',
}) {
  // eslint-disable-next-line no-undef
  const webJsSDK = await getWebJsSDK()
  await webJsSDK.init()
  const response = await webJsSDK.auth.getLoginStatus()

  // go to login if not yet
  if (forceLogin && (!response || response.status !== 'connected')) {
    const { publicRuntimeConfig } = getConfig()
    const redirectToHost = {
      app01webview: config.SSO.REDIRECT_TO_HOST,
      generalWeb: config.SSO.REDIRECT_TO_WEB_HOST,
    }[publicRuntimeConfig.SERVICE_SEGMENT]
    // make redirectUrl
    const _redirectTo = new URI(redirectToHost + pathname)
      .addQuery(query)
      .toString()
    webJsSDK.auth.login(
      _redirectTo, // for web
      () => {
        window.location.assign(_redirectTo) // for app
      },
      { isBindPhoneRequired: true, campaign: loginSource }
    )
  } else {
    window.location.reload()
  }

  const ssoToken = path(['response', 'accessToken'], response)
  let accessToken
  try {
    accessToken = path(
      ['wallet', 'token'],
      await webJsSDK.auth.getTokens(ssoToken)
    )
  } catch (e) {}

  return { ssoToken, accessToken }
}
