import React, { Fragment, useState, useCallback } from 'react'
import randomstring from 'randomstring'

const remountComponent = (Component) => {
  const WrappedComponent = (props) => {
    const [key, setKey] = useState(getNewKey())
    const remount = useCallback(() => {
      setKey(getNewKey())
    }, [])
    return (
      <Fragment key={`remount-toggle-${key}`}>
        <Component {...props} remount={remount} />
      </Fragment>
    )
  }
  return WrappedComponent
}
export default remountComponent

const getNewKey = () => randomstring.generate(5)
