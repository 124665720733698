import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

const styles = {
  root: {
    backgroundColor: 'transparent',
  },
}

const FeedbackView = ({ open, handleClose, classes, iconNode, text }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <Body>
        <Container>
          <Icon>{iconNode}</Icon>
          <Text>{text}</Text>
        </Container>
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  width: 88px;
  background-color: rgb(0, 0, 0, 0.4);
  border-radius: 6px;
  text-align: center;
  padding: 16px 0;
`

const Icon = styled.div`
  max-width: 88px;
`

const Text = styled.div`
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin-top: 8px;
`

export default withStyles(styles)(FeedbackView)
