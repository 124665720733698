import React from 'react'
import styled from 'styled-components'
import { desktopSize } from 'theme'
import { useUserAgentStore } from 'store/userAgentStore'

const CouponFooter = () => {
  const year = new Date().getFullYear()
  const { isInAppOrWebView } = useUserAgentStore()
  if (isInAppOrWebView()) return null
  return (
    <FooterWrapper>
      <FooterText>香港01有限公司版權所有@{year}</FooterText>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background: ${(p) => p.theme.color.footerBgColor};
  height: 64px;
  width: 100%;
  padding: 0 24px;
  line-height: 64px;
  text-align: center;
  box-sizing: border-box;
  @media ${desktopSize} {
    text-align: right;
    border-top: 0;
  }
`
const FooterText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${(p) => p.theme.color.footerColor};
`
export default CouponFooter
