// relative import for next.config.js usage.
const envUtil = require('../utils/envUtil')
const registeredEnvironments = [
  'local',
  'testing',
  'development',
  'staging',
  'production',
]

const defaultConfiguration = require('./config-default')
const configuration = registeredEnvironments.includes(envUtil.ENV)
  ? require(`./config-${envUtil.ENV}`)
  : {}

module.exports = { ...defaultConfiguration, ...configuration }
