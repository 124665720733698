export const [defaultColor, darkModeColor] = (() => {
  if (typeof window === 'undefined') {
    const getConfig = require('next/config').default
    const { serverRuntimeConfig } = getConfig()
    const isAppConstant = {
      app01webview: true,
      generalWeb: false,
    }[serverRuntimeConfig.SERVICE_SEGMENT]
    if (isAppConstant) {
      const appTheme = require('theme/app')
      return [appTheme.lightModeColor, appTheme.darkModeColor]
    } else {
      const webTheme = require('theme/web')
      return [webTheme.lightModeColor, webTheme.darkModeColor]
    }
  }
  const getConfig = require('next/config').default
  if (!getConfig) {
    // for testing env only:
    const appTheme = require('theme/app')
    return [appTheme.lightModeColor, appTheme.darkModeColor]
  }
  const publicRuntimeConfig = getConfig()?.publicRuntimeConfig
  const isAppConstant = {
    app01webview: true,
    generalWeb: false,
  }[publicRuntimeConfig?.SERVICE_SEGMENT]
  if (isAppConstant) {
    const appTheme = require('theme/app')
    return [appTheme.lightModeColor, appTheme.darkModeColor]
  } else {
    const webTheme = require('theme/web')
    return [webTheme.lightModeColor, webTheme.darkModeColor]
  }
})()

const theme = {
  color: defaultColor,
  media: {
    iPhoneSE:
      'only screen and (device-width: 320px)and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)',
    iPhonePlus:
      'only screen and (device-width: 414px)and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)',
    iPhoneX:
      'only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
    generalSafeAreaForIphoneXAbove:
      'only screen and (min-device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)',
    generalSafeAreaForXR:
      'only screen and (min-device-width: 414px) and (min-device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)',
  },

  defaultIphoneSafeAreaSpace: '34px',

  desktopMaxWidth: '1280px',
  tabletLandscape: 1023,
  desktopLandscape: 1024,
  mobileLandscape: 599,
}

export const tabletSize = `(max-width: ${theme.tabletLandscape}px)`
export const desktopSize = `(min-width: ${theme.desktopLandscape}px)`
export const mobileSize = `(max-width: ${theme.mobileLandscape}px)`

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
}

export default theme
