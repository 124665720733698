const baseColor = {
  blue400: '#001b4d',
  blue300: '#0238b3',
  blue200: '#0033e8',
  blue100: '#4574ff',
  blue90: '#5294ff',
  blue70: '#97BEFF',
  blue50: '#d5e1ff',
  blue20: '#f5f6fa',

  rosiness200: '#e72a58',
  rosiness100: '#F07F9A',
  rosiness300: '#f07f9a',
  orange: '#ffac27',

  white: '#ffffff',
  grey50: '#ecf0f3',
  grey80: '#e3e3e3',
  grey100: '#cacfd1',

  grey200: '#c6c6c6',
  grey300: '#9fa3a6',
  grey400: '#838383',
  grey500: '#6a6a6a',
  grey600: '#525252',
  grey700: '#3c3c3c',
  grey800: '#2c2c2c',
  grey900: '#242424',
  grey1000: '#1e1e1e',
  grey1100: '#121212',

  dark100: '#3b3b3b',
  dark200: '#252525',

  black: '#000000',

  overlay87: 'rgba(255, 255, 255, 0.87)',
  overlay60: 'rgba(255, 255, 255, 0.60)',
  overlay38: 'rgba(255, 255, 255, 0.38)',
  overlay8: 'rgba(255, 255, 255, 0.08)',
  overlay80: 'rgba(255, 255, 255, 0.80)',
}
export default baseColor
