const config = require('config')
const Hashids = require('hashids/cjs')

export const composeShareContentURL = (queryPath) =>
  config.WEB_SERVER.WEB_BASE_URL + queryPath

// TODO: Add Unit Test
// TODO: we should have our own next plugin in regards
export const permaLinkHashQueryString = (asPath, project, key) => {
  const currentURL = new URL(asPath, `${config.WEB_SERVER.WEB_BASE_URL}`)
  const { pathname, search } = currentURL
  const currentFastFlowHash = currentURL.searchParams.get('fast')
  const today = new Date()
  const todayGMT = today.valueOf().toString()
  const hashIds = new Hashids(project, 12)
  const hashIdsEncoded = currentFastFlowHash || hashIds.encode(todayGMT)
  if (search) {
    const params = new URLSearchParams(search)
    params.delete('fast')
    return `${config.WEB_SERVER.WEB_BASE_URL}${pathname}?${key}=${hashIdsEncoded}${params}`
  }
  return `${config.WEB_SERVER.WEB_BASE_URL}${pathname}?${key}=${hashIdsEncoded}`
}
