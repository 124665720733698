const bugsnag = require('@bugsnag/js')
const config = require('config')
const envUtil = require('utils/envUtil')
const packageJson = require('@/package.json')
const BugsnagPluginReact = require('@bugsnag/plugin-react')

const bugsnagClient = bugsnag.start({
  apiKey: config.BUGSNAG_API_KEY,
  maxBreadcrumbs: 40,
  maxEvents: 10,
  notifyReleaseStages: ['staging', 'production'],
  releaseStage: envUtil.ENV,
  appVersion: packageJson.version,
  plugins: [new BugsnagPluginReact()],
})

export default bugsnagClient
