import styled from 'styled-components'
import { desktopSize } from 'theme'

export const DesktopOnly = styled.div`
  display: none;
  @media ${desktopSize} {
    display: block;
  }
`

export const MobileOnly = styled.div`
  @media ${desktopSize} {
    display: none;
  }
`
