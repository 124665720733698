import React from 'react'
import styled from 'styled-components'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import InstallToHome from 'static/images/install-to-home.png'
import DarkInstallToHome from 'static/images//darkMode/install-to-home.png'
import SafariAddSvg from 'static/images/safari-add-icon.svg'
import { mobileSize } from 'theme'
import { DesktopOnly, MobileOnly } from 'components/LayoutUtils'

const SmartBanner = ({ isIOSOrSafari, isDarkMode, buttonClick, onClose }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        {!isIOSOrSafari && (
          <MobileOnly>
            <CloseButton marginright={8} onClick={onClose} />
          </MobileOnly>
        )}
        <Image src={isDarkMode ? DarkInstallToHome : InstallToHome} />
        <TipText>
          <p>添加《01優惠券》至主畫面，隨時查看最新優惠！</p>
          {isIOSOrSafari && (
            <AddTips>
              點擊 <AddIcon /> 然後選擇 ’加至主畫面’
            </AddTips>
          )}
        </TipText>
        {isIOSOrSafari && <CloseButton onClick={onClose} />}
        {!isIOSOrSafari && (
          <>
            <DesktopOnly>
              <TextButton onClick={onClose}>再諗諗</TextButton>
            </DesktopOnly>
            <AddButton onClick={buttonClick}>立即添加</AddButton>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 16px;
  right: 14px;
  width: 400px;
  z-index: 2000;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${(p) => p.theme.color.cardBgColor};
  @media ${mobileSize} {
    left: 0;
    top: auto;
    right: auto;
    bottom: 0;
    width: 100%;
    border-radius: 0;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`
const Image = styled.img`
  width: 48px;
  height: 48px;
`
const TipText = styled.div`
  margin-left: 12px;
  font-size: 14px;
  color: ${(p) => p.theme.color.masterTitle};
  flex: 1;
  line-height: 1.5;
`
const AddTips = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color.subTitle};
  display: flex;
  align-items: center;
`
const AddIcon = styled(SafariAddSvg)`
  padding: 0 8px;
`
const CloseButton = styled(CloseRoundedIcon)`
  font-size: 16px;
  color: ${(p) => p.theme.color.smartBanner.closeBtn};
  margin-right: ${(p) => p.marginright}px;
`
const TextButton = styled.div`
  color: ${(p) => p.theme.color.couponBlue};
  font-size: 14px;
  margin: 0 12px;
  width: 42px;
  cursor: pointer;
`
const AddButton = styled.div`
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.color.couponBlue};
  color: ${(p) => p.theme.color.white};
  text-align: center;
  cursor: pointer;
`

export default SmartBanner
