import React from 'react'
import * as R from 'ramda'
import { useStaticRendering } from 'mobx-react'
import App from 'next/app'
import Head from 'next/head'
import theme, { darkModeColor, defaultColor } from 'theme'
import CombinedStoreProvider from 'store'
import bugsnagClient from 'utils/bugsnagUtil'
import Layout from 'components/Layout'
import WebPush from 'components/WebPush'
import LoadingSpinner from 'components/LoadingSpinner'
import NetworkInfo from 'components/NetworkInfo'
import GlobalStyle from 'components/GlobalStyle'
import ErrorModal from 'components/ErrorModal'
import ErrorModalV2 from 'components/ErrorModalV2'
import ErrorPage from 'pages/_error'
import 'swiper/swiper-bundle.min.css'
import InstallMessageModal from 'components/InstallMessageModal'
import {
  isIOSOrAndroidVersionNotBelowTen,
  renderOnClientSide,
} from 'utils/commonUtil'

// This is not a hook!
// eslint-disable-next-line react-hooks/rules-of-hooks
useStaticRendering(!process.browser)

const ErrorBoundary = bugsnagClient
  .getPlugin('react')
  .createErrorBoundary(React)

export default class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return {
      pageProps,
      userAgent: R.pathOr({}, ['req', 'useragent', 'source'])(ctx),
      pathname: ctx.pathname,
      featureFlags: R.pathOr({}, ['req', 'featureFlags'])(ctx),
      serviceSegment: R.pathOr({}, ['req', 'couponServiceSegment'])(ctx),
    }
  }

  render() {
    const {
      Component,
      pageProps,
      userAgent,
      pathname,
      featureFlags,
      serviceSegment,
    } = this.props

    const darkMode = (() => {
      // when userAgent === string, is render in server
      const ua =
        typeof window !== 'undefined' ? window.navigator.userAgent : userAgent

      // workaround for android useragent issue: https://github.com/react-native-webview/react-native-webview/issues/1971
      // for webview in android, the very first request's useragent is accurate, so we will save it to
      // session storage and use it later.
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('initUA', ua)
      }
      const initUA =
        typeof window !== 'undefined'
          ? window.sessionStorage.getItem('initUA')
          : ''
      // workaround for android useragent issue: https://github.com/react-native-webview/react-native-webview/issues/1971

      const matchResult = initUA && initUA.match(/\bdarkMode\/(\w+)/)
      return isIOSOrAndroidVersionNotBelowTen(initUA) && matchResult
        ? matchResult[1].toLowerCase() === 'true'
        : false
    })()
    theme.color = darkMode ? darkModeColor : defaultColor
    return (
      <>
        {/* ErrorPage also needs global style */}
        <GlobalStyle />
        <Head>
          <meta
            content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover"
            name="viewport"
          />
          <title>01優惠券 | 香港01</title>
        </Head>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <CombinedStoreProvider
            theme={theme}
            userAgent={userAgent}
            featureFlags={featureFlags}
            darkMode={darkMode}
            pathname={pathname}
            serviceSegment={serviceSegment}
          >
            <>
              <ErrorModalV2 />
              <ErrorModal />
              <InstallMessageModal />
              <WebPush featureFlags={featureFlags} />
              <LoadingSpinner />
              <NetworkInfo />
              {renderOnClientSide(
                <Layout
                  pathname={pathname}
                  featureFlags={featureFlags}
                  isDarkMode={darkMode}
                >
                  <Component {...pageProps} />
                </Layout>
              )}
            </>
          </CombinedStoreProvider>
        </ErrorBoundary>
      </>
    )
  }
}
