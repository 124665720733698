import { COUPON_PROJECT } from 'constants/coupon'
import { useLocalStore } from 'mobx-react-lite'
import * as R from 'ramda'
import React, { useContext } from 'react'
import { permaLinkHashQueryString } from 'utils/urlUtil'

const ShareStoreContext = React.createContext(null)

export const ShareStoreProvider = ({ children, pathname }) => {
  const defaultURL = permaLinkHashQueryString(
    '',
    COUPON_PROJECT.coupon_web,
    'fast'
  )
  const defaultTitle = '01優惠券' // This is not in use in 01 App code base.
  const defaultMsg = '分享「01優惠券」俾朋友，著數一齊享！\n'
  const sharablePages = ['/skus', '/sku']
  const defaultSharableContent = {
    url: defaultURL,
    title: defaultTitle,
    message: defaultMsg,
  }
  const store = useLocalStore(() => ({
    // only sku listing page and sku detail page is sharable
    isSharablePage: R.contains(pathname, sharablePages),
    shareContentPayload: defaultSharableContent,

    // TODO: is there any query string identifier for sharable link ?
    setSharablePayload: (url, title, message) => {
      store.shareContentPayload = {
        url,
        title,
        message,
      }
    },
  }))
  return (
    <ShareStoreContext.Provider value={store}>
      {children}
    </ShareStoreContext.Provider>
  )
}

export function useShareStore() {
  const store = useContext(ShareStoreContext)
  if (!store) {
    throw new Error('You have forgot to use shareStore, shame on you.')
  }
  return store
}
