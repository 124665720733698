import React, { useState, useEffect } from 'react'
import { useNetworkState } from 'react-use'

import SimpleSnackbar from 'components/SimpleSnackbar'

const NetworkInfo = () => {
  const state = useNetworkState()
  const { online } = state
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(online === false)
  }, [online])
  return (
    <SimpleSnackbar
      open={open && process.browser}
      handleClose={() => setOpen(false)}
      message={'網絡連接有問題，請稍後再試'}
      disableBackdropClick
    />
  )
}

export default NetworkInfo
