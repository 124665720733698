import { useEffect, useState } from 'react'
import * as R from 'ramda'

import { getBridgeEventSDK } from 'utils/sdkUtil'

// Add Unit Test for this hook
const useSdk = () => {
  const [sdk, setSDK] = useState()

  async function initSDK() {
    const sdk = await getBridgeEventSDK()
    setSDK(R.pick(['EVENT_ENUMS', 'requestAndWait', 'isSupportedApp'], sdk))
  }

  useEffect(() => {
    initSDK()
  }, [])

  return sdk
}

export default useSdk
