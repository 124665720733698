import React, { useEffect, useState, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { useServiceSegmentStore } from 'store/serviceSegmentStore'
import { useUserAgentStore } from 'store/userAgentStore'
import useBreakpoint from 'hooks/useBreakpoint'
import useAddToHomescreenPrompt from 'hooks/useAddToHomescreenPrompt'
import { usePWAPromptStore } from 'store/pwaPromptStore'
import { useTrackingStore } from 'store/trackingStore'
import { BUTTON_NAME, COUPON_SCREEN_NAME, EVENT_ACTION } from 'tracking'

import SmartBanner from './SmartBanner'
import SuccessSnack from 'components/UI/SuccessSnack'

const INSTALL_BANNER_SHOWN_COOKIE = 'INSTALL_BANNER_SHOWN_COOKIE'
const InstallMessageModal = observer(() => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt()

  const [installBannerVisible, setInstallBannerVisible] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { embedIn01FamilyApp } = useServiceSegmentStore()
  const userAgentStore = useUserAgentStore()
  const router = useRouter()
  const { trackEvent } = useTrackingStore()
  const isIOS = userAgentStore.isIOS()
  const isSafari = userAgentStore.isSafari()
  const isInAppOrWebView = userAgentStore.isInAppOrWebView()
  const isDarkMode = userAgentStore.isDarkMode
  const { isMobileBreakpoint } = useBreakpoint()
  const { setPrompt } = usePWAPromptStore()
  const closeSnackbar = () => setOpenSnackbar(false)

  const installPopupMessageShown = Cookies.get(INSTALL_BANNER_SHOWN_COOKIE)
  const setInstallPopupMessageShown = (value) => {
    // set cookie 1d(24h)
    Cookies.set(INSTALL_BANNER_SHOWN_COOKIE, value, {
      expires: 1,
      secure: true,
    })
  }

  const screenName = router.pathname.substr(1).toUpperCase()

  const trackPwaPopup = useCallback(() => {
    trackEvent(EVENT_ACTION.POPUP, {
      screen: COUPON_SCREEN_NAME[screenName],
      type: 'pwa_installation',
    })
  }, [trackEvent, screenName])

  // show bind phone message modal
  useEffect(() => {
    if (prompt) {
      if (!installPopupMessageShown && !embedIn01FamilyApp) {
        setInstallBannerVisible(true)
        setInstallPopupMessageShown(true)
        trackPwaPopup()
      }
      setPrompt(prompt)
    }
  }, [
    embedIn01FamilyApp,
    installPopupMessageShown,
    prompt,
    setPrompt,
    trackPwaPopup,
  ])
  useEffect(() => {
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone

    // Checks if should display install popup notification:
    if (
      (isIOS || isSafari) &&
      !isInAppOrWebView &&
      !isInStandaloneMode() &&
      !installPopupMessageShown &&
      !embedIn01FamilyApp
    ) {
      setInstallBannerVisible(true)
      setInstallPopupMessageShown(true)
      trackPwaPopup()
    }
  }, [
    embedIn01FamilyApp,
    installPopupMessageShown,
    isIOS,
    isSafari,
    isInAppOrWebView,
    trackPwaPopup,
  ])

  const closeModal = useCallback(() => {
    setInstallBannerVisible(false)
    trackEvent(EVENT_ACTION.CLICK, {
      screen: COUPON_SCREEN_NAME[screenName],
      button: BUTTON_NAME.DISMISS_PWA,
    })
  }, [screenName, trackEvent])

  const handleInstall = useCallback(() => {
    trackEvent(EVENT_ACTION.CLICK, {
      screen: COUPON_SCREEN_NAME[screenName],
      button: BUTTON_NAME.INSTALL_PWA,
      system: prompt ? prompt.platforms : null,
    })
    promptToInstall()
    prompt.userChoice.then(function (result) {
      if (result.outcome === 'accepted') {
        // install to home screen
        setOpenSnackbar(true)
        setInstallBannerVisible(false)
        trackEvent(EVENT_ACTION.RESPONSE, {
          screen: COUPON_SCREEN_NAME[screenName],
          button: BUTTON_NAME.INSTALL_PWA,
          system: prompt.platforms,
        })
      } else {
        // user refuse to install
        setInstallBannerVisible(false)
        trackEvent(EVENT_ACTION.RESPONSE, {
          screen: COUPON_SCREEN_NAME[screenName],
          button: BUTTON_NAME.INSTALL_PWA,
          result: JSON.stringify(result),
          system: prompt.platforms,
          error_type: 'user rejected',
        })
      }
    })
  }, [prompt, promptToInstall, screenName, trackEvent])

  useEffect(() => {
    if (installBannerVisible) {
      setTimeout(() => {
        setInstallBannerVisible(false)
      }, 10000)
    }
  }, [installBannerVisible])
  return (
    <>
      {installBannerVisible && (
        <SmartBanner
          isIOSOrSafari={isIOS || isSafari}
          buttonClick={handleInstall}
          onClose={closeModal}
          isDarkMode={isDarkMode}
        />
      )}
      <SuccessSnack
        successText="添加成功！"
        openSnackbar={openSnackbar}
        closeSnackbar={closeSnackbar}
        vertical={isMobileBreakpoint ? 'bottom' : 'top'}
      />
    </>
  )
})

export default InstallMessageModal
