import React, { useMemo, useState } from 'react'

export const CustomHeaderContext = React.createContext()

export const CustomHeaderProvider = ({ children }) => {
  const [tapToUseCountdown, setTapToUseCountdown] = useState()
  // const UpperSlotContainer = ReactDOM.createPortal()
  const value = useMemo(
    () => ({
      tapToUseCountdown,
      setTapToUseCountdown,
    }),
    [tapToUseCountdown]
  )
  return (
    <CustomHeaderContext.Provider value={value}>
      {children}
    </CustomHeaderContext.Provider>
  )
}
