import React, { useCallback } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { useTrackingStore } from 'store/trackingStore'
import { COUPON_SCREEN_NAME, TrackingActionType } from 'tracking/app/constants'

const HomeTabs = ({ pathname, remount }) => {
  return (
    <TabsWrapper>
      <LinkTab
        pathname="/skus"
        title={'全部'}
        currentPathname={pathname}
        remount={remount}
      />

      <LinkTab
        pathname="/mycoupon"
        title={'我的'}
        currentPathname={pathname}
        remount={remount}
      />
    </TabsWrapper>
  )
}

const LinkTab = ({ pathname, title, currentPathname, remount }) => {
  const { trackEvent } = useTrackingStore()
  const active = currentPathname === pathname
  const onTabClick = useCallback(() => {
    let pressType = 'skuPress'
    let screenName = COUPON_SCREEN_NAME.SKUS
    if (currentPathname !== '/skus') {
      pressType = 'couponPress'
      screenName = COUPON_SCREEN_NAME.MY_COUPON
    }
    const toListing = pathname === '/skus' ? 'sku_all_list' : 'sku_my_list'
    trackEvent(TrackingActionType.tabs[pressType], {
      screen_name: screenName,
      to_listing: toListing,
    })
    // click tab to refresh current pages
    if (active) remount()
  }, [active, currentPathname, pathname, remount, trackEvent])
  return (
    <Link href={pathname} replace>
      <Tab active={active} onClick={onTabClick}>
        {title}
      </Tab>
    </Link>
  )
}

const TabsWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  background-color: ${(p) => p.theme.color.homeTabBgColor};
  z-index: 10;
  flex: 0 0 auto;
`

const Tab = styled.span`
  font-size: 16px;
  flex: 1;
  text-align: center;
  line-height: 56px;
  color: ${(p) => p.theme.color.homeTabColor};
  &:hover {
    border-bottom: 2px solid ${(p) => p.theme.color.primary};
  }

  ${(p) =>
    p.active &&
    css`
      color: ${(p) => p.theme.color.masterTitle} !important;
      border-bottom: 2px solid ${(p) => p.theme.color.primary};
    `}
`

export default HomeTabs
