import config from 'config'
import getConfig from 'next/config'
import * as R from 'ramda'
import { v4 as uuidv4 } from 'uuid'

export async function getBridgeEventSDK() {
  return require('@hk01-digital/react-native-webview-events/cjs/web')
}

export async function getWebJsSDK(options) {
  const { publicRuntimeConfig } = getConfig()
  const { SERVICE_SEGMENT, APP_VERSION } = publicRuntimeConfig
  const defaultOptions = {
    anonymousId: uuidv4(),
    sessionId: null,
    service: '01 coupon',
    appVersion: APP_VERSION,
  }
  const appId = {
    app01webview: config.SSO.APP_ID,
    generalWeb: config.SSO.WEB_APP_ID,
  }[SERVICE_SEGMENT]
  const baseApiUri = {
    app01webview: R.dissoc('baseApiUri'),
    generalWeb: R.assoc('baseApiUri', `${config.SSO.WEB_SSO_DOMAIN}`),
  }[SERVICE_SEGMENT]
  const WebJsSdk = require('@hk01-digital/web-js-sdk').default

  return await new WebJsSdk(
    R.pipe(
      R.dissoc('trackerClientOptions'),
      R.assoc('appId', appId),
      baseApiUri
    )({ ...defaultOptions, ...options }),
    R.prop('trackerClientOptions', options)
  )
}

export async function loginThenReload(loginSource) {
  const jsSDK = await getWebJsSDK()
  await jsSDK.init()
  return jsSDK.auth.login(
    undefined,
    () => {
      window.location.reload()
    },
    { campaign: loginSource || 'normal-login' }
  )
}

export async function bindPhoneThenReload(trackSource) {
  const jsSDK = await getWebJsSDK()
  await jsSDK.init()
  return jsSDK.auth
    .bindPhone(
      undefined,
      () => {
        window.location.reload()
      },
      { campaign: trackSource || 'normal-bind-phone' }
    )
    .catch(() => {
      window.location.reload()
    })
}

export async function logoutThenReload(trackSource) {
  const jsSDK = await getWebJsSDK()
  await jsSDK.init()
  return jsSDK.auth
    .logout(
      undefined,
      () => {
        window.location.reload()
      },
      { campaign: trackSource || 'normal-logout' }
    )
    .catch(async () => {
      const response = await getWebJsSDK().auth.getLoginStatus()
      if (!response || response.status !== 'connected') {
        window.location.reload()
      }
    })
}
