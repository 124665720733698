import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { CustomHeaderContext } from 'store/customHeaderStore'
import { useUserAgentStore } from 'store/userAgentStore'
import { useServiceSegmentStore } from 'store/serviceSegmentStore'
import NavigationHeader from 'components/NavigationHeader'
import TapToUseCountdown from 'components/TapToUseCountdown'
import HomeTabs from 'components/HomeTabs'
import withRemount from 'HOC/withRemount'
import { mobileSize, tabletSize } from 'theme'
import CouponFooter from 'components/CouponFooter'
import dynamic from 'next/dynamic'
import { DesktopOnly } from 'components/LayoutUtils'

const Layout = ({
  children,
  pathname,
  remount,
  isDarkMode,
  hideNavigationHeader,
  applyFullScreenFix,
  ...props
}) => {
  const userAgentStore = useUserAgentStore()
  const { embedIn01FamilyApp } = useServiceSegmentStore()
  const { isInAppOrWebView } = userAgentStore
  const { tapToUseCountdown } = useContext(CustomHeaderContext)
  const isHomePages = ['/skus', '/mycoupon'].includes(pathname)
  const isSettingsPage = pathname === '/settings'

  if (embedIn01FamilyApp) {
    return (
      <>
        <StyledLayout {...props} isInAppOrWebView={isInAppOrWebView}>
          <TopSafeAreaOffset />
          {!hideNavigationHeader && <NavigationHeader />}
          {isHomePages && <HomeTabs pathname={pathname} remount={remount} />}
          {tapToUseCountdown && (
            <UpperSlot>
              <TapToUseCountdown timeout={tapToUseCountdown} />
            </UpperSlot>
          )}
          <ContentWrapper
            // backgroundColor={isHomePages ? theme.color.bgColor : null}
            applyFullScreenFix={applyFullScreenFix}
          >
            <InnerContent>{children}</InnerContent>
          </ContentWrapper>
        </StyledLayout>
        <FixedWrap id="fixedWrap" />
      </>
    )
  }

  // TODO: WAL-5271: https://hk01-digital.atlassian.net/browse/WAL-5271
  // Don't know the root cause yet and add the related Jira card here, for further investigation.
  const showBottomTabs = isHomePages || isSettingsPage
  const CouponHeader = dynamic(import('components/CouponHeader'))
  const BottomTabs = dynamic(import('components/BottomTabs'))
  return (
    <div>
      <FlexWrapper>
        <CouponHeader pathname={pathname} />
        <ContentWrapperV2 isDarkMode={isDarkMode}>
          <InnerContentV2
            isSettingsPage={isSettingsPage}
            showBottomTabs={showBottomTabs}
          >
            {children}
          </InnerContentV2>
        </ContentWrapperV2>
        {showBottomTabs && <BottomTabs />}
        <DesktopOnly>
          <CouponFooter />
        </DesktopOnly>
      </FlexWrapper>
      <FixedWrap id="fixedWrap" />
      <div id="fb-root" />
    </div>
  )
}

const StyledLayout = styled.div`
  /* for, e.g. iPhone css safe area */
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  height: ${(p) =>
    p.isInAppOrWebView ? '100vh' : 'calc(100vh - env(safe-area-inset-top))'};
  display: flex;
  flex-direction: column;
  overscroll-behavior: none;
`

export const TopSafeAreaOffset = styled.div`
  padding-top: env(safe-area-inset-top, 0px);

  /* to cover the background image on coupon page */
  background-color: ${(p) => p.theme.color.topSafeAreaBgColor};
`

const UpperSlot = styled.div`
  z-index: 3;
`

const ContentWrapper = styled.div`
  /* for smooth scrolling on iOS */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  background-color: ${(p) => p.theme.color.bgColor};
  /* to fix -webkit-overflow-scrolling: touch occasionally gets stuck or cannot scroll */
  position: static;
  z-index: 1;
  display: flex;
  flex-direction: column;
  /**
   * To make "height: 100%" of child working on Safari,
   * need to set flex-basis to "0".
   * https://github.com/philipwalton/flexbugs/issues/197#issuecomment-283211783
   */
  flex: 1 1 0;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom, 0px);
`
const ContentWrapperV2 = styled.div`
  background-color: ${(p) => p.theme.color.pagebgColor};
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom, 0px);
  ::-webkit-scrollbar {
    display: none;
  }
`
const InnerContentV2 = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 16px 24px;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  position: relative;
  @media ${tabletSize} {
    padding: 16px;
    margin-bottom: ${(p) => p.showBottomTabs && '56px'};
  }
  @media ${mobileSize} {
    padding: 8px 12px;
    margin-bottom: ${(p) =>
      p.showBottomTabs && 'calc(env(safe-area-inset-bottom, 0px) + 56px)'};
  }
  ${(p) =>
    p.isSettingsPage &&
    css`
      padding: 0 !important;
      background-color: ${p.theme.color.settingPage.bgColor};
    `}
`

const InnerContent = styled.div`
  flex: 1;
  /* to fix -webkit-overflow-scrolling: touch occasionally gets stuck or cannot scroll */
  min-height: calc(100% + 1px);
`
const FixedWrap = styled.div`
  z-index: 1000; /* redeem popup z-index 1300 */
  position: relative;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: calc(100vh - env(safe-area-inset-bottom, 0px));
  padding-top: env(safe-area-inset-top, 0px);
`
export default withRemount(Layout)
