import React from 'react'
import AlertDialog from 'components/UI/AlertDialog'
import { useErrorStore } from 'store/errorStore'
import { observer } from 'mobx-react'
import Router from 'next/router'
import * as R from 'ramda'
const ErrorModal = observer(() => {
  const {
    errorModalV2: { visible },
    title,
    description,
    closeModal,
    redirectTo,
    actionButtonText,
    buttonOnClick,
  } = useErrorStore()
  const cancelButtonText = '關閉'
  const handleRouteChange = () => {
    if (visible) {
      closeModal()
    }
  }
  Router.events.on('routeChangeStart', handleRouteChange)
  const redirectToUrl = () => Router.push(redirectTo)
  const closeAction = !R.isNil(redirectTo) ? redirectToUrl : closeModal
  const getErrorButtonsActionContext = () => {
    if (redirectTo) {
      return {
        buttonText: actionButtonText || cancelButtonText,
        buttonClick: redirectToUrl,
      }
    }
    return {
      buttonText: actionButtonText || cancelButtonText,
      buttonClick: buttonOnClick || closeAction,
    }
  }
  return (
    <AlertDialog
      open={visible}
      handleClose={closeAction}
      title={title}
      description={description}
      {...getErrorButtonsActionContext()}
    />
  )
})

export default ErrorModal
