import React, { useContext } from 'react'
import { useLocalStore } from 'mobx-react-lite'
import * as UAParser from 'ua-parser-js'
import * as R from 'ramda'

const UserAgentStoreContext = React.createContext(null)

const parseUserAgent = (userAgent) => {
  if (!userAgent) {
    return {}
  }
  const uaParser = new UAParser()
  uaParser.setUA(userAgent)
  return {
    os: uaParser.getOS(),
    browser: uaParser.getBrowser(),
    device: uaParser.getDevice(),
  }
}

export const UserAgentStoreProvider = ({
  userAgent,
  featureFlags,
  darkMode,
  children,
}) => {
  const store = useLocalStore(() => ({
    userAgent,
    featureFlags,
    isAndroid: () => {
      const osName = R.path(['os', 'name'], parseUserAgent(store.userAgent))
      return osName ? osName.toLowerCase() === 'android' : false
    },
    isIOS: () => {
      const osName = R.path(['os', 'name'], parseUserAgent(store.userAgent))
      return osName ? osName.toLowerCase() === 'ios' : false
    },
    isSafari: () => {
      const browserName = R.path(
        ['browser', 'name'],
        parseUserAgent(store.userAgent)
      )
      return browserName ? browserName.toLowerCase() === 'safari' : false
    },
    isWebview: () => {
      const rules = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari/)',
        'Android.*(wv|.0.0.0)',
      ]
      const regex = new RegExp(`(${rules.join('|')})`, 'ig')
      return R.test(regex, store.userAgent)
    },

    // !! This won't work properly in 01 app (Android) now as of Sep 18, 2020
    isHK01App: () => R.test(/app\/com\.hk01\.news[_-]?app/, store.userAgent),
    isMobile: () =>
      R.pathEq(['device', 'type'], 'mobile', parseUserAgent(store.userAgent)),
    isInAppOrWebView: () => store.isHK01App() || store.isWebview(),
    isPC: () => !store.isMobile(),
    isDarkMode: darkMode,
    isPWA: () =>
      typeof window !== 'undefined' &&
      (('standalone' in window.navigator && window.navigator.standalone) ||
        window.matchMedia('(display-mode: standalone)').matches),
  }))
  return (
    <UserAgentStoreContext.Provider value={store}>
      {children}
    </UserAgentStoreContext.Provider>
  )
}

export function useUserAgentStore() {
  const store = useContext(UserAgentStoreContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('You have forgot to use userAgentStore, shame on you.')
  }
  return store
}
