// for internal use only
import React, { useContext } from 'react'
import { useLocalStore } from 'mobx-react-lite'

function createMobxStoreCtx(createStoreFn) {
  const StoreContext = React.createContext(null)

  const StoreProvider = ({ children }) => {
    const store = useLocalStore(createStoreFn)
    return (
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    )
  }

  const useStore = () => {
    const store = useContext(StoreContext)
    if (!store) {
      // this is especially useful in TypeScript so you don't need to be checking for null all the time
      throw new Error('You have forgot to use StoreProvider, shame on you.')
    }
    return store
  }

  return [StoreProvider, useStore]
}

export default createMobxStoreCtx
