import { createGlobalStyle } from 'styled-components'
import theme from 'theme'
import { normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }
  body {
    overflow-x: hidden;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif;
    margin: 0;
    padding: 0;

    overscroll-behavior: none;
    height: 100%;
    width: 100%;
  }
  html, body {
    background: ${theme.color.pageColor};
  }
  p {
    margin: 0;
    padding: 0;
  }
`

export default GlobalStyle
