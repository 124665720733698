import React from 'react'
import Head from 'next/head'
import { observer } from 'mobx-react'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { composeShareContentURL } from 'utils/urlUtil'
import couponLogo from '/static/images/coupon-logo.png'

const SkuList = {
  app01webview: dynamic(() => import('/components/Skus/SkuList')),
  generalWeb: dynamic(() => import('/components/SkusV2/SkuList')),
}

const BindPhoneModals = {
  app01webview: dynamic(() => import('/components/BindPhoneMessageModal')),
  generalWeb: dynamic(() => import('/components/BindPhoneMessageModalV2')),
}

const Skus = observer(() => {
  const { publicRuntimeConfig } = getConfig()
  const SkuListing = SkuList[publicRuntimeConfig.SERVICE_SEGMENT]
  const BindPhoneMessageModal =
    BindPhoneModals[publicRuntimeConfig.SERVICE_SEGMENT]
  const router = useRouter()
  const pageUrl = composeShareContentURL(router.asPath)
  const ogImageUrl = composeShareContentURL(couponLogo)

  return (
    <>
      <Head>
        <title>01優惠券 | 香港01</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={ogImageUrl} />
      </Head>
      <BindPhoneMessageModal />
      <SkuListing />
    </>
  )
})

export default Skus
