import React from 'react'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Spinner from 'components/UI/Spinner'

const modalStyle = {
  display: 'flex',
  outline: 'none',
  alignItems: 'center',
}

const AlertDialog = ({
  open,
  handleClose,
  image,
  SvgComponent,
  title,
  description,
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
  isLoading,
}) => {
  const topImage = image ? (
    <Image src={image} />
  ) : SvgComponent ? (
    <SvgComponent />
  ) : null

  const ModalButtons = () => {
    if (isLoading) {
      return (
        <Button>
          <Spinner size={24} />
        </Button>
      )
    }
    return (
      <>
        {leftButtonText && (
          <Button onClick={onLeftButtonClick}>{leftButtonText}</Button>
        )}
        <Button onClick={onRightButtonClick}>{rightButtonText}</Button>
      </>
    )
  }
  return (
    <Modal open={open} onClose={handleClose} style={modalStyle}>
      <Body>
        <CloseButton onClick={handleClose} />
        {topImage}
        {title && <Title marginTop={topImage ? '24px' : 0}>{title}</Title>}
        {description && (
          <Description marginTop={title ? '8px' : topImage ? '24px' : 0}>
            {description}
          </Description>
        )}
        <ButtonGroup>
          <ModalButtons />
        </ButtonGroup>
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 295px;
  margin: 0 auto;
  padding-top: 24px;
  background-color: ${(p) => p.theme.color.modalBgColor};
  border-radius: 4px;
  position: relative;
  outline: none;
`

const CloseButton = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  opacity: 0.3;

  &:before,
  &:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: ${(p) => p.theme.color.modalCloseButtonColor};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Image = styled.img`
  width: 160px;
  height: 160px;
`

const Title = styled.div`
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  color: ${(p) => p.theme.color.modalColor};
  margin-top: ${(p) => p.marginTop};
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 500;
`

const Description = styled.div`
  margin-top: ${(p) => p.marginTop};
  font-size: 14px;
  line-height: 1.71;
  color: ${(p) => p.theme.color.modalDescriptionColor};
  padding: 0 24px;
`

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
`

const Button = styled.span`
  flex-grow: 1;
  font-size: 16px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  width: 50%;
  border-top: 1px solid ${(p) => p.theme.color.borderColor};
  user-select: none;

  &:first-child {
    color: ${(p) => p.theme.color.modalFirstButtonColor};
    border-right: 1px solid ${(p) => p.theme.color.borderColor};
  }
  &:last-child {
    color: ${(p) => p.theme.color.primary};
  }
`

export default AlertDialog
