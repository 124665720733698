export const COUPON_STATUS = {
  AVAILABLE: 'AVAILABLE',
  RESERVED: 'RESERVED',
  REDEEMED: 'REDEEMED',
  USED: 'USED',
  WAIVED: 'WAIVED',
  WAIVED_FOR_REFUND: 'WAIVED_FOR_REFUND',
  EXPIRED: 'EXPIRED',
  RETURNED: 'RETURNED',
  FROZEN: 'FROZEN',
}

export const COUPON_STATUS_MAP = {
  AVAILABLE: '可兌換',
  RESERVED: '已保留',
  REDEEMED: '已兌換',
  USED: '已使用',
  WAIVED: '已撤銷',
  WAIVED_FOR_REFUND: '已撤銷(退款)',
  EXPIRED: '已過期',
  RETURNED: '已退回',
  FROZEN: '已凍結',
  UNAVAILABLE: '失效',
}

export const COUPON_REDEEMABLE_STATUS = {
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  INSUFFICIENT_POINTS: 'INSUFFICIENT_POINTS',
  NO_QUOTA: 'NO_QUOTA',
  REDEEM_OK: 'REDEEM_OK',
  DEFAULT: 'DEFAULT', // 在還沒獲取到數據的時候的默認狀態
}

export const COUPON_DISPLAY_TYPE = {
  TEXT: 'TEXT',
  QR: 'QR',
  BAR_CODE_CODE128: 'BAR_CODE_CODE128',
  BAR_CODE_EAN13: 'BAR_CODE_EAN13',
  BAR_CODE_PDF417: 'BAR_CODE_PDF417',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
}

export const COUPON_TYPE = {
  OFFLINE_VOUCHER: 'OFFLINE_VOUCHER', // 商戶券
  ONLINE_DISCOUNT: 'ONLINE_DISCOUNT', // 現金券 - 打折
  ONLINE_DEDUCTION: 'ONLINE_DEDUCTION', // 現金券 - 満减
}

export const USE_TYPES = {
  TAP_TO_USE: 'TAP_TO_USE',
  PIN: 'PIN',
  SCAN: 'SCAN',
  REUSE: 'REUSE',
}

export const COUPON_PROJECT = {
  coupon_web: 'coupon_web',
  coupon_app: 'coupon_app',
}

export const COUPON_PLATFORM = {
  HK01_APP: 'HK01_APP',
  COUPON_WEB: 'COUPON_WEB',
}

export const COUPON_PAYMENT_STATUS = {
  PENDING: 'PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
}

export const COMMON_VAR_NAME = {
  HK01_WALLET_T: 'hk01_wallet_t',
}
