import * as R from 'ramda'
import { loginOrGetToken } from 'utils/loginUtil'
import bugsnagUtil from 'utils/bugsnagUtil'
import createMobxStoreCtx from './_createStore'
import * as errorUtil from 'utils/errorUtil'

function createStore() {
  return {
    errorModal: {
      visible: false,
      title: null,
    },
    errorModalV2: {
      visible: false,
      title: null,
    },
    err: null,
    onModalClosed: null,
    toggleModal() {
      this.errorModal.visible = !this.errorModal.visible
    },
    openErrorModalV2(err, options = {}) {
      this.errorModalV2.visible = true
      this.err = err
      this.actionButtonText = options.actionButtonText
      this.redirectTo = options.backToListing
      this.onModalClosed = options.onModalClosed
      // TODO: confirm default text
      this.title = options.title || '未知錯誤'
      const errorInfo =
        err && err.code
          ? `錯誤碼：${R.propOr(null, 'code', err, err.code)}`
          : '無法連接到伺服器，請重試'
      this.description = options.description || errorInfo
      this.buttonOnClick = options.buttonOnClick
    },
    openErrorModal(err, options = {}) {
      this.errorModal.visible = true
      this.err = err
      this.actionButtonText = options.actionButtonText
      this.redirectTo = options.backToListing
      this.onModalClosed = options.onModalClosed
      // TODO: confirm default text
      this.title = options.title || '未知錯誤'
      const errorInfo =
        err && err.code
          ? `錯誤碼：${R.propOr(null, 'code', err, err.code)}`
          : '無法連接到伺服器，請重試'
      this.description = options.description || errorInfo
      this.retryButtonText = options.retryButtonText
      this.onRetry = options.onRetry
    },
    closeModal() {
      this.errorModal.visible = false
      this.errorModalV2.visible = false
      R.is(Function, this.onModalClosed) && this.onModalClosed()
    },
    handleAjaxError(orignialError, options, isDesktop) {
      const err = R.path(['response', 'data', 'error', 'code'], orignialError)
        ? orignialError.response.data.error
        : orignialError

      if (R.pathEq(['response', 'status'], 401)(orignialError)) {
        // should redirect to login page
        loginOrGetToken({ pathname: window.location.pathname })
        return
      }
      bugsnagUtil.notify(err)
      isDesktop
        ? this.openErrorModalV2(err, options)
        : this.openErrorModal(err, options)
    },
    async wrapErrorHandler(ajax, isDesktop) {
      try {
        await ajax()
      } catch (err) {
        const errCode = R.path(['response', 'data', 'error', 'code'], err)
        const errorContextOpts = errorUtil.getErrorMessageContext({ errCode })
        this.handleAjaxError(err, errorContextOpts, isDesktop)
      }
    },
  }
}

const [ErrorStoreProvider, useErrorStore] = createMobxStoreCtx(createStore)

export { ErrorStoreProvider, useErrorStore }
