import React from 'react'
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import Spinner from 'components/UI/Spinner'
import CloseRounded from '@material-ui/icons/CloseRounded'
import { mobileSize } from 'theme'
import { useUserAgentStore } from 'store/userAgentStore'

const modalStyle = { display: 'flex', outline: 'none', alignItems: 'center' }

const AlertDialog = ({
  open,
  handleClose,
  buttonText,
  subButtonText,
  buttonClick,
  subButtonClick,
  title,
  description,
  isLoading,
  image,
  SvgComponent,
  children,
}) => {
  const topImage = image ? (
    <Image src={image} />
  ) : SvgComponent ? (
    <SvgComponent />
  ) : null
  const userAgentStore = useUserAgentStore()
  const { isDarkMode } = userAgentStore
  const AlertButton = () => {
    if (isLoading) {
      return (
        <Button disabled={isLoading} isDarkMode={isDarkMode}>
          <Spinner customcolor="#ffffff" size={16} />
        </Button>
      )
    }
    return (
      <>
        <Button onClick={buttonClick} isDarkMode={isDarkMode}>
          {buttonText}
        </Button>
        {subButtonText && (
          <SubButton onClick={subButtonClick} isDarkMode={isDarkMode}>
            {subButtonText}
          </SubButton>
        )}
      </>
    )
  }
  return (
    <Modal open={open} onClose={handleClose} style={modalStyle}>
      <AlertBody isDarkMode={isDarkMode}>
        <CloseButton onClick={handleClose} />
        <Container append={!!children}>
          {topImage}
          {title && (
            <Title marginTop={topImage ? '20px' : 0} isDarkMode={isDarkMode}>
              {title}
            </Title>
          )}
          {description && (
            <Description isDarkMode={isDarkMode}>{description}</Description>
          )}
          {buttonText && <AlertButton>{buttonText}</AlertButton>}
        </Container>
        {children}
      </AlertBody>
    </Modal>
  )
}

const AlertBody = styled.div`
  position: relative;
  text-align: center;
  background-color: ${(p) =>
    p.isDarkMode ? p.theme.color.modalBgColor : '#fff'};
  color: ${(p) => p.isDarkMode && p.theme.color.overlay87};
  border: 1px solid ${(p) => p.isDarkMode && p.theme.color.overlay8};

  min-width: 400px;
  max-width: 480px;
  min-height: 180px;
  border-radius: 5px;
  margin: 0 auto;
  outline: none;
  box-sizing: border-box;
  @media ${mobileSize} {
    min-width: 280px;
    max-width: 90%;
  }
`
const Container = styled.div`
  padding: 32px 24px 24px 24px;
  ${(p) => p.append && 'padding-bottom: 16px;'}
`
const CloseButton = styled(CloseRounded)`
  color: #878b92;
  position: absolute;
  right: 8px;
  top: 8px;
`
const Image = styled.img`
  width: 160px;
  height: 160px;
`
const Title = styled.div`
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  margin-top: ${(p) => p.marginTop};
  font-weight: 600;
  padding-bottom: 4px;
  color: ${(p) => p.theme.color.textColor};
`
const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto;
  margin-top: 4px;
  color: ${(p) => p.theme.color.textColor};
`
const Button = styled.div`
  width: 192px;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  background: ${(p) =>
    p.disabled
      ? p.theme.color.disableButtonBgColor
      : p.isDarkMode
      ? p.theme.color.blue90
      : p.theme.color.blue200};
  line-height: 40px;
  font-size: 16px;
  margin: auto;
  margin-top: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SubButton = styled.div`
  font-size: 14px;
  color: ${(p) =>
    p.isDarkMode ? p.theme.color.blue90 : p.theme.color.blue200};
  line-height: 1.5;
  margin-top: 16px;
  cursor: pointer;
`
export default AlertDialog
