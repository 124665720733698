module.exports = {
  WEB_SERVER: {
    PORT: 3000,
    BASE_URL: 'https://coupon.hk01.com',
    WEB_BASE_URL: 'https://01coupon.com',
  },
  SSO: {
    APP_ID: '97d426b2-daba-4d59-82a1-a2bed39ee721',
    WEB_APP_ID: 'ac7f8e79-6f47-405a-94ae-503919e29eb9',
    REDIRECT_TO_HOST: 'https://coupon.hk01.com',
    REDIRECT_TO_WEB_HOST: 'https://01coupon.com',
    WEB_SSO_DOMAIN: 'https://sso.01coupon.com/api/v1.0',
  },
  COUPON_API: {
    SERVER_URL: 'https://wallet.wezeroplus.com/coupon-service/',
    TIMEOUT: 30000,
  },
  PUSHER: {
    KEY: 'bbac96f2799eb4f84be7',
    CLUSTER: 'ap1',
    FORCE_TLS: true,
    AUTH_ENDPOINT: 'https://wallet.wezeroplus.com/pusher/auth',
  },
  TRACK: {
    PIWIK_APP_ENDPOINT: 'https://track.hk01.com/v2/piwik.php',
    GA_ID: 'UA-70981149-41',
    GA4_ID: 'G-YM8YSNNV09',
    APP_SITE_ID: '6',
    PIWIK_WEB_ENDPOINT: 'https://track.01coupon.com/v2/piwik.php',
    WEB_SITE_ID: '62',
  },
  APP_INFO: {
    SCHEME: 'hk01',
    PACKAGE_NAME: 'com.hk01.news_app',
  },
  CAMPAIGN: {
    COOLDOWN: 30, // in seconds
  },
  FB_APPID: '1651866545051541',
  ONE_SIGNAL_APP_ID: '12f95681-4054-4ef6-83e1-dc2d381bf701',
}
