import React, { useEffect } from 'react'
import bugsnagClient from 'utils/bugsnagUtil'
import Error401Page from 'components/errorPages/Error401Page'
import Error404Page from 'components/errorPages/Error404Page'
import Error500Page from 'components/errorPages/Error500Page'

const ErrorPage = ({
  error,
  statusCode,
  // eslint-disable-next-line no-unused-vars
  info,
  bugsnagReportAlreadySent,
}) => {
  // send bugsnag error report
  useEffect(() => {
    if (error && !bugsnagReportAlreadySent) {
      bugsnagClient.notify(error)
    }
  }, [bugsnagReportAlreadySent, error])

  if (statusCode === 404) {
    return <Error404Page />
  }

  if (statusCode === 401) {
    return <Error401Page />
  }

  return <Error500Page />
}

ErrorPage.getInitialProps = ({ req, res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null
  const error = err || req.errorFromPageErrorHandler

  // server error detail is not passed to frontend for security concern
  if (error) {
    bugsnagClient.notify(error)
  }
  return { statusCode, bugsnagReportAlreadySent: !!error }
}

export default ErrorPage
