import Errors from 'constants/coupon_errors.json'

export const getErrorMessageContext = ({
  errCode,
  replaceObj,
  onModalClosed,
  options,
}) => {
  if (errCode && Errors[errCode]) {
    const { title, message, backToListing, actionButtonText } = Errors[errCode]
    return {
      title: replaceByRegex(title, replaceObj),
      description: replaceByRegex(message, replaceObj),
      backToListing,
      actionButtonText,
      onModalClosed,
    }
  }
  return options
}

function replaceByRegex(str, obj) {
  return str.replace(/\{(\w+)\}/g, (_, key) => {
    return obj ? obj[key] : ''
  })
}
