import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

const SimpleSnackbar = ({
  open,
  handleClose,
  message,
  autoHideDuration = null,
  disableBackdropClick = false,
}) => {
  const onCloseEvent = () => {
    if (disableBackdropClick) return
    handleClose()
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onCloseEvent}
      message={<span>{message}</span>}
      action={[
        <IconButton key="close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>,
      ]}
      TransitionComponent={Slide}
    />
  )
}

export default SimpleSnackbar
