import styled from 'styled-components'

const Button = styled.div`
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
  border-radius: 4px;
  color: ${(p) =>
    p.disabled ? p.theme.color.disableButtonColor : p.theme.color.buttonColor};
  background-color: ${(p) =>
    p.disabled
      ? p.theme.color.disableButtonBgColor
      : p.theme.color.buttonBgColor};
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`

export default Button
