import React from 'react'
import Image500 from '/static/images/ic-500.svg'
import DarkImage500 from '/static/images/darkMode/ic-500.svg'
import ErrorPageBase from './ErrorPageBase'
import { useUserAgentStore } from 'store/userAgentStore'

const Error500Page = () => {
  const userAgentStore = useUserAgentStore()
  const { isDarkMode } = userAgentStore
  const ErrorImage = isDarkMode ? <DarkImage500 /> : <Image500 />
  return <ErrorPageBase img={ErrorImage} description={'伺服器發生錯誤'} />
}

export default Error500Page
