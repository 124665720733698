module.exports = {
  WEB_SERVER: {
    PORT: 3001,
    BASE_URL: 'http://localhost:3001',
    WEB_BASE_URL: 'http://localhost:3001',
  },
  SSO: {
    APP_ID: '0f7890ecc1cb0b7f23336a3430df7127',
    WEB_APP_ID: '25b7c1da-e2a0-49f7-8e05-d1ee88234fdf',
    REDIRECT_TO_HOST: 'http://localhost:3001',
  },
  COUPON_API: {
    SERVER_URL: 'http://wallet.hktester.com/coupon-service/',
    TIMEOUT: 30000,
  },
  APP_INFO: {
    SCHEME: 'hk01uat',
    PACKAGE_NAME: 'com.hk01.news_app.debug',
  },
  CAMPAIGN: {
    COOLDOWN: 30, // in seconds
  },
  FB_APPID: '1244227315689301',
  ONE_SIGNAL_APP_ID: '0d8be65e-4b0c-4d85-b529-e3150e1d0c37',
}
