import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useUserAgentStore } from 'store/userAgentStore'

const useBreakpoint = () => {
  const userAgentStore = useUserAgentStore()
  const isMobile = userAgentStore.isMobile()
  const theme = useTheme()
  const matchesS = useMediaQuery(theme.breakpoints.down('xs'))
  const matchesM = useMediaQuery(theme.breakpoints.down('sm'))

  return {
    matchesS,
    matchesM,
    isMobileBreakpoint: isMobile || matchesS,
  }
}

export default useBreakpoint
