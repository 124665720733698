const env = (() => {
  if (typeof window === 'undefined') {
    // injected by k8s
    return process.env.APP_ENV || 'local'
  }
  if (window.Cypress) {
    // special handling for Cypress
    return 'testing'
  }
  const getConfig = require('next/config').default
  if (!getConfig()) {
    return 'testing'
  }
  const { publicRuntimeConfig } = getConfig()
  return publicRuntimeConfig.APP_ENV
})()

const isLocal = env === 'local'
const isDevelopment = env === 'development'
const isStaging = env === 'staging'
const isProd = env === 'production'
const isTesting = env === 'testing'

module.exports = {
  ENV: env,
  isTesting,
  isLocal,
  isDevelopment,
  isStaging,
  isProd,
  isNotProd: !isProd,
  isStagingOrProd: isStaging || isProd,
  isDeployment: isDevelopment || isStaging || isProd,
}
