export const EVENT_CATEGORY = 'coupon'

export const EVENT_ACTION = {
  VIEW: 'view',
  CLICK: 'click',
  POPUP: 'popup',
  REACH: 'reach',
  RESPONSE: 'response',
  WEB_PUSH: 'webpush',
}

export const COUPON_SCREEN_NAME = {
  SKUS: 'skus',
  SKU: 'sku',
  COUPON: 'coupon',
  MYCOUPON: 'mycoupon',
  SETTINGS: 'settings',
  BIND_PHONE: 'bind_phone',
  LOGIN: 'login',
  USERGUIDE: 'user_guide',
}

export const BUTTON_NAME = {
  LOGO: '01logo',
  SEARCH: 'search',
  TAB: 'tab',
  COUPON: 'coupon',
  SHARE: 'share',
  BIND_PHONE: 'bind_phone',
  LOGIN: 'login',
  LOGOUT: 'logout',
  REDEEM: 'redeem',
  CONFIRM_REDEEM: 'confirm_redeem',
  CANCEL_REDEEM: 'cancel_redeem',
  USE_NOW: 'use_now',
  REDEEM_AGAIN: 'redeem_again',
  COPY_CODE: 'copy_code',
  USE_COUPON: 'use_coupon',
  SUBMIT_PIN: 'submit_pin',
  INSTALL_PWA: 'install_pwa',
  DISMISS_PWA: 'dismiss_pwa',
  OPEN_APP: 'open_app_use_coupon',
  CUSTOM_SHARE_FB: 'customShare_fb',
  CUSTOM_SHARE_WHATSAPP: 'customShare_whatsapp',
  CUSTOM_SHARE_COPY: 'customShare_copy_link',
  CUSTOM_SHARE_CANCEL: 'customShare_cancel',
  REDIRECT_EARN_POINTS: 'redirect_earn_points',
  REDIRECT_TNC: 'redirect_tnc',
  PWA_INSTALL_GUIDE: 'pwa_install_guide',
}

export const TrackingActionType = Object.freeze({
  webPush: {
    shown: 'slide.prompt.is.shown.to.user',
    accept: 'user.accept.receiving.notifications.from.01coupon',
    dismiss: 'user.dismiss.receiving.notifications',
    subscriptionChanged: 'user.re-subscribe.our.notifications',
    updateUserId: 'one.signal.userId.update',
  },
})
