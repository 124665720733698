import React, { useCallback } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from 'theme'
import Button from 'components/Button'

const ErrorPageBase = ({ img, description, buttonText, buttonClick }) => {
  const onClick = useCallback(() => {
    buttonClick ? buttonClick() : window.history.back()
  }, [buttonClick])
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {img}
        {description && <Description>{description}</Description>}
        <ButtonWrapper>
          <Button onClick={onClick}>{buttonText || '返回「香港01」'}</Button>
        </ButtonWrapper>
      </Wrapper>
    </ThemeProvider>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${(p) => p.theme.color.bgColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`

const Description = styled.div`
  margin-top: 24px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
`

const ButtonWrapper = styled.div`
  margin-top: 24px;
`

export default ErrorPageBase
