import baseColor from '../baseTheme.js'

export const lightModeColor = {
  pagebgColor: 'initial',
  primary: baseColor.blue200, // 'rgb(0, 51, 232)',
  bgColor: baseColor.blue20, // 'rgb(245, 246, 250)',
  modalColor: '#1a1e28', // 'rgb(26, 30, 40)',
  modalBgColor: baseColor.white,
  modalDescriptionColor: '#525252',
  modalFirstButtonColor: '#464d57',
  modalCloseButtonColor: '#333',

  importantColor: '#ff001b', // rgb(255, 0, 27),
  buttonBgColor: baseColor.blue200,
  buttonColor: baseColor.white,
  disableButtonColor: baseColor.overlay38,
  disableButtonBgColor: 'rgb(162, 181, 249)',
  tagColor: baseColor.orange, // 热门，限兑
  homeTabBgColor: baseColor.white,
  homeTabColor: 'rgba(26, 30, 40, 0.3)',

  masterTitle: '#03070f', // rgb(3, 7, 15),
  subTitle: '#9f9f9f', // rgb(159, 159, 159)
  skuDetailBg: '#e8eaf6',
  skuCardRightBg: 'rgba(255, 255, 255, 0.85)',
  skuQuotaLabelColor: baseColor.black,
  skuPointsLabelColor: baseColor.blue300,
  skuTextOverhangGradient:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8))',

  contentPrimaryBgColor: baseColor.white,
  descriptionBgColor: baseColor.blue20,
  bottomBarBgColor: baseColor.white,
  bottomBarColor: baseColor.dark200,
  termsColor: baseColor.black,
  infoColor: '#1a1e28', // rgb(26,30,40)
  tncColor: baseColor.dark200,
  borderColor: '#e3e3e3',
  couponSubTitle: 'rgb(60, 67, 78)',
  couponDisableColor: 'rgba(255, 255, 255, 0.75)',
  textCodeColor: baseColor.dark200,
  copyButtonColor: 'rgba(255,255,255,0.87)',

  contentLoader: {
    backgroundColor: '#e3e3e3',
    foregroundColor: '#f3f3f3',
  },
  countdownColor: baseColor.white,

  navigationDistinctColor: '#1a1e28',
  navigationColor: '#fbfcfd',
  navigationTitleColor: '#1a1e28',

  emptyPage: {
    descriptionColor: baseColor.dark100,
  },

  pinInput: {
    activeBorderColor: 'rgb(69, 116, 225)',
    borderColor: 'rgb(217, 217, 217)',
    color: 'initial',
  },

  keyboard: {
    bgColor: baseColor.white,
    borderColor: baseColor.grey80,
    numberColor: baseColor.black,
    dismissBarBgColor: baseColor.grey50,
    dismissBarBorderColor: baseColor.grey100,
  },

  topSafeAreaBgColor: 'transparent',

  ...baseColor,
}

export const darkModeColor = {
  pagebgColor: baseColor.grey1100,
  primary: baseColor.blue90,
  bgColor: baseColor.grey1100,
  modalColor: baseColor.overlay87,
  modalDescriptionColor: baseColor.overlay87,
  modalBgColor: baseColor.grey700,
  modalFirstButtonColor: baseColor.overlay87,
  modalCloseButtonColor: baseColor.overlay87,

  homeTabBgColor: baseColor.grey800,
  homeTabColor: baseColor.overlay38,

  importantColor: baseColor.rosiness100,
  buttonBgColor: baseColor.blue90,
  buttonColor: baseColor.overlay87,
  disableButtonColor: baseColor.overlay38,
  disableButtonBgColor: '#3C3C3C',
  tagColor: '#ffcd7d', // 热门，限兑

  masterTitle: baseColor.overlay87, // rgb(3, 7, 15),
  subTitle: baseColor.overlay60, // rgb(159, 159, 159)
  skuDetailBg: baseColor.grey1100,
  skuCardRightBg: baseColor.grey900,
  skuQuotaLabelColor: baseColor.overlay87,
  skuPointsLabelColor: baseColor.blue90,
  skuTextOverhangGradient:
    'linear-gradient(to bottom, rgba(36, 36, 36, 0), #242424)',

  contentPrimaryBgColor: baseColor.grey900,
  descriptionBgColor: '#1e1e1e',
  bottomBarBgColor: baseColor.grey800,
  bottomBarColor: baseColor.overlay87,
  termsColor: baseColor.overlay60,
  infoColor: baseColor.overlay87,
  tncColor: baseColor.overlay87,
  borderColor: baseColor.overlay8,
  couponSubTitle: baseColor.overlay60,
  couponDisableColor: 'rgba(18, 18, 18, 0.75)',
  textCodeColor: baseColor.overlay87,
  copyButtonColor: baseColor.black,

  contentLoader: {
    backgroundColor: baseColor.grey800,
    foregroundColor: baseColor.grey700,
  },
  countdownColor: baseColor.black,

  navigationDistinctColor: baseColor.overlay87,
  navigationColor: baseColor.grey800,
  navigationTitleColor: baseColor.overlay60,

  emptyPage: {
    descriptionColor: baseColor.overlay87,
  },

  pinInput: {
    activeBorderColor: baseColor.blue90,
    borderColor: baseColor.overlay8,
    color: baseColor.overlay87,
  },

  keyboard: {
    bgColor: baseColor.grey700,
    borderColor: baseColor.overlay8,
    numberColor: baseColor.overlay87,
    dismissBarBgColor: baseColor.grey700,
    dismissBarBorderColor: baseColor.overlay8,
  },

  topSafeAreaBgColor: '#2C2C2C',

  ...baseColor,
}
