// Inside 01 app webview
import { shape, number, string, oneOf, boolean } from 'prop-types'

export const TrackingPayloadType = shape({
  account_id: number,
  sku_id: string.isRequired,
  sku_type: string,
  sku_title: string.isRequired,
  display_type: string,
  is_feature: boolean,
  is_enable: boolean,
  redeem_quota: string,
  is_display_serial_number: string,
  use_type: string,
  tap_to_use_timeout: string,
  low_threshold: string,
  coupon_id: string.isRequired,
  item_ref: oneOf(['DEEP_LINK', 'IN_APP']),
  item_position: number,
  screen_name: string,
})

export const FireActionType = shape({
  category: string,
  action: string,
  label: TrackingPayloadType,
})

export const EVENT_CATEGORY = {
  COUPONS: 'coupon_my_list_available',
  SKUS: 'sku_all_list',
  COUPON_DETAIL: 'coupon',
  SKU_DETAIL: 'sku',
  COUPON_INFO: 'coupon_info',
}

export const EVENT_ACTION = {
  TAP_COUPON: 'tap_coupon',
  REACH_LOGIN: 'reach_login',
  CLICK_LOGIN: 'click_login',
  CLICK_CANCEL: 'click_cancel',
  CLICK_STORE: 'click_store',
  CLICK_VIEW_MORE: 'click_view_more',
  CLICK_BIND_PHONE: 'click_bind_phone',
  REACH_BIND_PHONE: 'reach_bind_phone',
  CLICK_INFO: 'click_info',
  CLICK_ITEM: 'click_item',
  REACH_ITEM: 'reach_item',
  CLICK_CHECK_DISABLED_COUPON: 'click_check_disabled_coupon',
  CLICK_REDEEM: 'click_redeem',
  CLICK_REDEEM_LOGIN: 'click_redeem_login',
  CLICK_REDEEM_CONFIRM: 'click_redeem_confirm',
  CLICK_REDEEM_BIND_PHONE: 'click_redeem_bind_phone',
  REACH_REDEEM_CONFIRM: 'reach_redeem_confirm',
  REDEEM_SUCCEED: 'redeem_succeed',
  CLICK_REDEEM_AGAIN: 'click_redeem_again',
  ENLARGE_COUPON: 'enlarge_coupon',
  CONSUME_COUPON: 'consume_coupon',
  VIEW_ITEM: 'view_item',
  REDEEM_FAILED: 'redeem_failed',
  CLICK_REDEEM_RETRY: 'click_redeem_retry',
  CLICK_MY_COUPON: 'click_my_coupon',
  CLICK_COPY_CODE: 'click_copy_code',
  CLICK_SHOW_CODE: 'click_show_code',
  REACH_SHOW_CODE_MODAL: 'reach_show_code_modal',
  CLICK_SHOW_CODE_MODAL_CONFIRM: 'click_show_code_modal_confirm',
  SHOW_CODE_SUCCEEDED: 'show_code_succeeded',
  SHOW_CODE_FAILED: 'show_code_failed',

  COLLECT_PIN_FIRST_INPUT: 'collect_pin_first_input',
  COLLECT_PIN_SUBMIT: 'collect_pin_submit',
  COLLECT_PIN_SUCCEEDED: 'collect_pin_succeeded',
  COLLECT_PIN_FAILED: 'collect_pin_failed',

  CLICK_INFO_DETAILS: 'click_info_details',
  CLICK_INFO_ITEM_DETAILS: 'click_info_item_details',

  VIEW: 'view',
  CLICK_TAB: 'click_tab',
  REACH_LOGIN_MODAL: 'reach_login_modal',
  REACH_BIND_PHONE_MODAL: 'reach_bind_phone_modal',
  CLICK_MODAL_CONFIRM: 'click_modal_confirm',
  CLICK_BIND_PHONE_MODAL_CONFIRM: 'click_bind_phone_modal_confirm',
  CLICK_SHARE: 'click_share',
  SHARE_SUCCEEDED: 'share_succeed',
  SHARE_FAILED: 'share_failed',
}

export const COUPON_SCREEN_NAME = {
  COUPON: 'coupon',
  SKU: 'sku',
  SKUS: 'skus',
  MY_COUPON: 'coupon_my_list_available',
  MY_COUPON_UNAVAILABLE: 'coupon_my_list_unavailable',
}

export const TrackingActionType = Object.freeze({
  skus: {
    view: 'sku.listing.page.view',
    tap: 'tap.on.one.sku.to.view.detail',
    showBindPhoneModal: 'bind.phone.modal.show.in.sku.listing',
    bindPhoneModalConfirm: 'bind.phone.modal.tap.confirm',
  },
  sku: {
    view: 'sku.detail.view',
    viewMore: 'sku.detail.view.more',
    cancel: 'sku.detail.top.left.hand.back',
  },
  tabs: {
    skuPress: 'sku.tab.tabbed',
    couponPress: 'coupon.tab.press',
  },
  mycoupon: {
    view: 'coupon.listing.page.view',
    tap: 'tap.on.one.coupon.to.view.detail',
    available: 'user.still.can.use.coupon',
    unavailable: 'user.expired.coupon',
    showLogin: 'login.button.show.in.coupon.list',
    login: 'tap.login.to.view.coupon.list',
    bindPhone: 'tap.bind.phone.to.view.coupon.list',
    showBindPhone: 'bind.phone.button.show.in.coupon.list',
  },
  coupon: {
    view: 'coupon.detail.view',
    viewMore: 'coupon.detail.view.more',
    tapToUse: 'at.my.coupon.detail.and.copy.code.tap.to.use',
    tapMerchant: 'at.my.coupon.detail.and.go.to.merchant',
    codeEnlarge: 'at.my.coupon.detail.and.tap.to.enlarge.code',
    showCouponCode: 'tap.to.show.coupon.code',
    showCouponCodeModal: 'coupon.code.confirm.modal.shown',
    couponCodeModalConfirm: 'coupon.code.modal.tap.confirm',
    cancel: 'coupon.detail.top.left.hand.back',
    showCodeSucceeded: 'coupon.code.show.succeeded',
    showCodeFailed: 'coupon.code.show.failed',
  },
  redeem: {
    tap: 'tap.the.redeem.button',
    login: 'tap.login.to.redeem',
    bindPhone: 'tap.bind.phone.to.redeem',
    confirmModal: 'redeem.modal.shown.to.user',
    tapConfirm: 'tap.confirm.on.redeem.modal',
    successCallback: 'redeem.api.call.success',
    failCallback: 'redeem.api.call.fail',
    again: 'tap.redeem.again',
    checkMyCoupon: 'check.my.coupon',
    tapRetry: 'tap.retry.on.failure.modal',
  },
  collectPin: {
    firstInput: 'collect.pin.page.merchant.staff.first.pin.input',
    submit: 'collect.pin.submit',
    submitSuccess: 'collect.pin.submit.success',
    submitFail: 'collect.pin.submit.fail',
  },
})
