import React from 'react'
import AlertDialog from 'components/AlertDialog'
import { useErrorStore } from 'store/errorStore'
import { observer } from 'mobx-react'
import Router from 'next/router'
import * as R from 'ramda'
const ErrorModal = observer(() => {
  const {
    errorModal: { visible },
    title,
    description,
    closeModal,
    retryButtonText,
    onRetry,
    redirectTo,
    actionButtonText,
  } = useErrorStore()
  const cancelButtonText = '關閉'
  const handleRouteChange = () => {
    if (visible) {
      closeModal()
    }
  }
  Router.events.on('routeChangeStart', handleRouteChange)
  const redirectToUrl = () => Router.push(redirectTo)
  const closeAction = !R.isNil(redirectTo) ? redirectToUrl : closeModal
  const getErrorButtonsActionContext = () => {
    if (redirectTo) {
      return {
        rightButtonText: actionButtonText || cancelButtonText,
        onRightButtonClick: redirectToUrl,
      }
    }
    if (onRetry) {
      return {
        rightButtonText: retryButtonText || '重試',
        onRightButtonClick: onRetry,
        leftButtonText: cancelButtonText,
        onLeftButtonClick: closeModal,
      }
    }
    return {
      rightButtonText: cancelButtonText,
      onRightButtonClick: closeAction,
    }
  }
  return (
    <AlertDialog
      open={visible}
      handleClose={closeAction}
      title={title}
      description={description}
      {...getErrorButtonsActionContext()}
    />
  )
})

export default ErrorModal
