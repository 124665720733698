import React from 'react'
import { observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import FeedbackView from 'components/FeedbackView'

import { useLoadingStore } from 'store/loadingStore'

const Spinner = withStyles({
  root: {
    color: '#ffffff',
  },
})(CircularProgress)

const LoadingSpinner = observer(() => {
  const store = useLoadingStore()
  return (
    <FeedbackView
      open={store.isLoading}
      iconNode={<Spinner size={36} />}
      text={'正在處理'}
    />
  )
})

export default LoadingSpinner
