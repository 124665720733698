const getAndroidVersion = (ua) => {
  ua = ua.toLowerCase()
  var match = ua.match(/android\s([0-9.]*)/i)
  return match ? match[1] : undefined
}

export const isSSR = !(
  typeof window !== 'undefined' && window.document?.createElement
)

// android dark mode workaround start.
// react-native-webview on android has this weird behaviour: react-native-webview/react-native-webview#1971
// our workaround will store the very first init user agent info into sessionStorage on client side
//  so some ssr component need to render on client side with correct user agent info.
export const renderOnClientSide = (comp) => {
  return typeof window !== 'undefined' && window.document?.createElement && comp
}
// android dark mode workaround end.

export const isInStandaloneMode = () =>
  typeof window !== 'undefined' &&
  (('standalone' in window.navigator && window.navigator.standalone) ||
    window.matchMedia('(display-mode: standalone)').matches)

export const isIOSOrAndroidVersionNotBelowTen = (ua) => {
  if (/iPad|iPhone|iPod/.test(ua)) {
    return true
  }
  const androidVersion = getAndroidVersion(ua)
  return parseInt(androidVersion, 10) >= 10
}
