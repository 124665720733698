module.exports = {
  WEB_SERVER: {
    PORT: 3000,
    BASE_URL: 'https://coupon.hktester.com',
    WEB_BASE_URL: 'https://staging.01coupon.com',
  },
  SSO: {
    APP_ID: 'e2cfa7a8-cc51-40f7-ba2c-53493290bdad',
    WEB_APP_ID: '25b7c1da-e2a0-49f7-8e05-d1ee88234fdf',
    REDIRECT_TO_HOST: 'https://coupon.hktester.com',
    REDIRECT_TO_WEB_HOST: 'https://staging.01coupon.com',
    WEB_SSO_DOMAIN: 'https://staging-sso.01coupon.com/api/v1.0',
  },
  COUPON_API: {
    SERVER_URL: 'https://wallet.hktester.com/coupon-service/',
    TIMEOUT: 30000,
  },
  APP_INFO: {
    SCHEME: 'hk01uat',
    PACKAGE_NAME: 'com.hk01.news_app.debug',
  },
  CAMPAIGN: {
    COOLDOWN: 30, // in seconds
  },
  FB_APPID: '1244227315689301',
  ONE_SIGNAL_APP_ID: 'ae88354f-12a7-472c-947d-a120e3615b1b',
}
