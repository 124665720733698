import React from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import { ServiceSegmentProvider } from 'store/serviceSegmentStore'
import { LoadingStoreProvider } from 'store/loadingStore'
import { UserAgentStoreProvider } from 'store/userAgentStore'
import { UserStoreProvider } from 'store/userStore'
import { ErrorStoreProvider } from 'store/errorStore'
import { TrackingStoreProvider } from 'store/trackingStore'
import { CustomHeaderProvider } from 'store/customHeaderStore'
import { ShareStoreProvider } from 'store/shareStore'
import { PWAPromptProvider } from 'store/pwaPromptStore'
import { breakpoints } from 'theme'

const muiTheme = createTheme({ breakpoints })
const CombinedStoreProvider = ({
  theme,
  userAgent,
  featureFlags,
  darkMode,
  serviceSegment,
  pathname,
  children,
}) => (
  <ServiceSegmentProvider serviceSegment={serviceSegment}>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <LoadingStoreProvider>
          <ShareStoreProvider pathname={pathname}>
            <UserStoreProvider>
              <UserAgentStoreProvider
                userAgent={userAgent}
                featureFlags={featureFlags}
                darkMode={darkMode}
              >
                <PWAPromptProvider>
                  <TrackingStoreProvider pathname={pathname}>
                    <ErrorStoreProvider>
                      <CustomHeaderProvider>{children}</CustomHeaderProvider>
                    </ErrorStoreProvider>
                  </TrackingStoreProvider>
                </PWAPromptProvider>
              </UserAgentStoreProvider>
            </UserStoreProvider>
          </ShareStoreProvider>
        </LoadingStoreProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </ServiceSegmentProvider>
)

export default CombinedStoreProvider
