import React, { useContext } from 'react'
import { useLocalStore } from 'mobx-react-lite'

const PWAPromptContext = React.createContext(null)

export const PWAPromptProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    prompt: null,
    setPrompt: (event) => {
      store.prompt = event
    },
    promptToInstall: () => {
      if (store.prompt) {
        return store.prompt.prompt()
      }
    },
    getPrompt: () => store.prompt,
  }))

  return (
    <PWAPromptContext.Provider value={store}>
      {children}
    </PWAPromptContext.Provider>
  )
}

export function usePWAPromptStore() {
  const store = useContext(PWAPromptContext)
  if (!store) {
    throw new Error('You have forgot to use pwa prompt context')
  }
  return store
}
