import React from 'react'
import Image401 from '/static/images/ic-401.svg'
import ErrorPageBase from './ErrorPageBase'
import Router from 'next/router'

const Error401Page = () => {
  const onClick = () => Router.push('/skus')
  return (
    <ErrorPageBase
      img={<Image401 />}
      description="無權查看此優惠券"
      buttonText="返回優惠券列表"
      buttonClick={onClick}
    />
  )
}

export default Error401Page
