import React, { useContext, useCallback, useState } from 'react'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import { useLocalStorage, useSessionStorage } from 'react-use'
import { useAsObservableSource } from 'mobx-react-lite'

import { getWebJsSDK } from 'utils/sdkUtil'
import useAutorun from 'hooks/useAutorun'

const UserStoreContext = React.createContext(null)
export const UserStoreProvider = observer(({ children }) => {
  const [checkingLoginStatus, setCheckingLoginStatus] = useState(true)
  // single source of truth of the JWT
  const [accessToken, setAccessToken] = useLocalStorage(
    'HK01_WAL_CLIENT_JWT',
    '',
    { raw: true }
  )
  const [bindPhonePopupMessageShown, setBindPhonePopupMessageShown] =
    useSessionStorage('BIND_PHONE_POPUP_SHOWN', false)

  // fetch sso user profile whenever reload / login
  const [userProfile, setUserProfile] = useState()
  // get the page load result whenever reload
  const [firstLoadingDone, setFirstLoadingDone] = useState(false)
  useAutorun(async () => {
    const webJsSDK = await getWebJsSDK()
    await webJsSDK.init()
    try {
      // reponse.data actually contains an object with keys "response" & "status"
      const { status, response } = await webJsSDK.auth.getLoginStatus()
      const { accessToken: ssoToken } = response
      if (status !== 'connected') {
        setAccessToken('')
        setUserProfile(null)
      } else {
        setAccessToken(
          R.path(['wallet', 'token'], await webJsSDK.auth.getTokens(ssoToken))
        )
        setUserProfile(await webJsSDK.auth.getProfile(ssoToken))
      }
    } catch (e) {
      // TODO: handle possible network error
      console.log(e)
    } finally {
      setFirstLoadingDone(true)
      setCheckingLoginStatus(false)
    }
  })

  const obState = useAsObservableSource({
    bindPhonePopupMessageShown,
    setBindPhonePopupMessageShown,
    accessToken,
    isLoggedIn: !!accessToken,
    userProfile,
    firstLoadingDone,
    hasBindPhone: useCallback(
      () =>
        R.pipe(
          R.propOr([], 'logins'),
          R.find(R.propEq('method', 'phone')),
          R.prop('verified'),
          R.equals(true)
        )(userProfile),
      [userProfile]
    ),
    checkingLoginStatus,
  })
  return (
    <UserStoreContext.Provider value={obState}>
      {children}
    </UserStoreContext.Provider>
  )
})

export function useUserStore() {
  const store = useContext(UserStoreContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('You forgot to use UserStoreProvider, shame on you.')
  }
  return store
}
