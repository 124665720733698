import useSdk from 'hooks/useSdk'
import debounce from 'lodash/debounce'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useShareStore } from 'store/shareStore'
import { useTrackingStore } from 'store/trackingStore'
import { useUserAgentStore } from 'store/userAgentStore'
import styled, { css } from 'styled-components'
import { COUPON_SCREEN_NAME, TrackingActionType } from 'tracking/app/constants'
import { isProd } from 'utils/envUtil'
import AndroidArrow from '/static/images/android-arrow.svg'
import IOSBack from '/static/images/ios-back.svg'
import ShareIcon from '/static/images/share-icon.svg'
import * as R from 'ramda'

const NavigationHeader = observer(({ redirectURL }) => {
  const router = useRouter()
  const userAgentStore = useUserAgentStore()
  const documentTitle = useDocumentTitle()
  const sdk = useSdk()
  const { pathname } = router
  const isHK01App = userAgentStore.isHK01App()
  const isAndroid = userAgentStore.isAndroid()
  const { featureFlags } = userAgentStore
  const fastShare = toJS(featureFlags.fastShare)
  const { trackEvent, isInited } = useTrackingStore()
  const { shareContentPayload } = useShareStore()
  const sharablePages = ['/skus', '/sku']
  // TODO: add proper tracking when confirm with PM
  const trackAppEvent = (info) => {
    trackEvent(info)
  }

  const callApp =
    (isAndroid, isHK01App, track) => (appEvent, bridgePayload, waitingTime) => {
      if (!sdk) return
      // if (!isHK01App) return
      setTimeout(async () => {
        const { EVENT_ENUMS, requestAndWait } = sdk
        try {
          const appBridgeEvent = EVENT_ENUMS[appEvent]
          await requestAndWait({
            type: appBridgeEvent,
            ...(bridgePayload && { payload: bridgePayload }),
          }).catch((err) => {
            console.log('share request error: ', err)
            track(err)
          })
        } catch (err) {
          console.log('general webview event error: ', err)
          track(err)
        }
      }, waitingTime)
    }
  const appEventBridge = callApp(isAndroid, isHK01App, trackAppEvent)
  const backIconTap = () => {
    if (!isInited) return
    const { query } = router
    const pageType = (() => {
      switch (router.pathname) {
        case '/sku':
          return 'sku'
        case '/coupon':
          return 'coupon'
        default:
          return undefined
      }
    })()
    if (pageType) {
      try {
        trackEvent(TrackingActionType[`${pageType}`].cancel, {
          id: query.id,
          screen_name: COUPON_SCREEN_NAME[`${pageType.toUpperCase()}_ITEM`],
        })
      } catch (err) {
        console.log('[left top backIconTap tracking cause error:]', err)
      }
    }
  }
  const handleTapToBack = () => {
    backIconTap()
    // Judging in webview is the value of the initial page
    const { pathname: currentPath } = router
    const { __INJECTED_IS_INITIAL } = window.history.state
    if (redirectURL) {
      window.location.assign(redirectURL)
    } else {
      if (
        currentPath === '/skus' ||
        currentPath === '/mycoupon' ||
        window.history.length <= 1 ||
        __INJECTED_IS_INITIAL
      ) {
        dismissWebViewAndGoBacktoApp()
      } else {
        window.history.go(-1)
        return true
      }
    }
  }

  const callToShare = () =>
    appEventBridge('OPEN_SHARE_DIALOG', toJS(shareContentPayload), 100)

  const handleTapToShare = debounce(callToShare, 400, { maxWait: 600 })

  const dismissWebViewAndGoBacktoApp = () =>
    appEventBridge('NAVIGATE_GO_BACK', null, 100)

  useEffect(() => {
    // Disable Native App webview
    appEventBridge('SET_NAVIGATION_OPTIONS', { hideHeader: true }, 400)
  }, [appEventBridge])

  // TODO: may keep this for a while, as Android 01 App has incorrect user-agent
  // as this navigation header only show in 01 App DesktopOnly

  // if (!isInHK01App) return null

  return (
    <Nav isAndroid={isAndroid}>
      <AppNavLeft isAndroid={isAndroid} onClick={handleTapToBack}>
        <AppNavContainer isAndroid={isAndroid}>
          <Icon isProd={isProd} isAndroid={isAndroid}>
            {isAndroid ? <AndroidArrow /> : <IOSBack />}
          </Icon>
          {isAndroid && (
            <AndroidHeaderTitle>{documentTitle}</AndroidHeaderTitle>
          )}
        </AppNavContainer>
      </AppNavLeft>
      <HeaderTitle isAndroid={isAndroid}>{documentTitle}</HeaderTitle>
      <AppNavRight
        onClick={handleTapToShare}
        fastShare={fastShare}
        isShareIconShown={R.contains(pathname, sharablePages)}
      >
        <ShareIconButton />
      </AppNavRight>
    </Nav>
  )
})

const useDocumentTitle = () => {
  const [documentTitle, setDocumentTitle] = useState('')
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDocumentTitle(window.document.title)
    }
  }, [])
  return documentTitle
}

const androidNavBorder = css`
  box-shadow: 0 1px 2px 0 rgba(3, 7, 15, 0.1);
`

const iOSNavBorder = css`
  box-shadow: 0 1px 2px 0 rgba(3, 7, 15, 0.1);
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  background-color: ${(p) => p.theme.color.navigationColor};
  padding: 10px 0 8px;
  line-height: 24px;
  width: 100vw;
  z-index: 100;
  ${(p) => (p.isAndroid ? androidNavBorder : iOSNavBorder)};
`

const HeaderTitle = styled.h3`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
  padding: 0;
  margin: -8px 8px -8px -8px;
  color: ${(p) => p.theme.color.navigationTitleColor};
  /* isAndroid */
  ${(p) =>
    p.isAndroid &&
    css`
      display: none;
    `}
`

const AppNavLeft = styled.div`
  display: inline-block;
  padding: 0;
  margin-left: ${(p) => (p.isAndroid ? '0' : '4px')};
  /* isAndroid */
  flex-basis: ${(p) => !p.isAndroid && 0};
  flex-grow: ${(p) => !p.isAndroid && 1};
  width: ${(p) => !p.isAndroid && '56px'};
  max-width: ${(p) => !p.isAndroid && '56px'};
`

const AppNavRight = styled.div`
  display: inline-block;
  padding: 0px 12px;
  width: 24px;
  height: 24px;
  ${(p) =>
    (!p.isShareIconShown || !p.fastShare) &&
    css`
      visibility: hidden;
    `}
`

const AppNavContainer = styled.a`
  display: flex;
  flex-direction: row;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  width: 100%;
  /* isAndroid */
  ${(p) =>
    p.isAndroid &&
    css`
      width: 250%;
    `}
`

const Icon = styled.div`
  width: 24px;
  height: 24px;

  path:nth-child(1) {
    fill: ${(p) => p.theme.color.navigationDistinctColor};
  }
  margin-left: ${(p) => p.isAndroid && '12px'};
`
const ShareIconButton = styled(ShareIcon)`
  path:nth-child(2) {
    stroke: ${(p) => !p.isAndroid && p.theme.color.navigationDistinctColor};
  }
`

const AndroidHeaderTitle = styled.div`
  font-size: 18px;
  display: inline-block;
  color: ${(p) => p.theme.color.navigationTitleColor};
  font-weight: 400;
  padding-left: 18px;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default NavigationHeader
