const [
  EVENT_CATEGORY,
  EVENT_ACTION,
  COUPON_SCREEN_NAME,
  TrackingActionType,
  BUTTON_NAME,
] = (() => {
  if (typeof window === 'undefined') {
    const getConfig = require('next/config').default
    const { serverRuntimeConfig } = getConfig()
    const isAppConstant = {
      app01webview: true,
      generalWeb: false,
    }[serverRuntimeConfig.SERVICE_SEGMENT]
    if (isAppConstant) {
      const appConst = require('tracking/app/constants')
      return [
        appConst.EVENT_CATEGORY,
        appConst.EVENT_ACTION,
        appConst.COUPON_SCREEN_NAME,
        appConst.TrackingActionType,
      ]
    } else {
      const webConst = require('tracking/web/constants')
      return [
        'coupon',
        webConst.EVENT_ACTION,
        webConst.COUPON_SCREEN_NAME,
        {},
        webConst.BUTTON_NAME,
      ]
    }
  }
  const getConfig = require('next/config').default
  if (!getConfig) {
    // for testing env only:
    const appConst = require('tracking/app/constants')
    const webConst = require('tracking/web/constants')
    return [
      appConst.EVENT_CATEGORY,
      appConst.EVENT_ACTION,
      appConst.COUPON_SCREEN_NAME,
      appConst.TrackingActionType,
      webConst.BUTTON_NAME,
    ]
  }
  const nextjsConfig = getConfig()

  // for storybook case
  if (!nextjsConfig) {
    return []
  }

  const { publicRuntimeConfig } = nextjsConfig
  const isAppConstant = {
    app01webview: true,
    generalWeb: false,
  }[publicRuntimeConfig.SERVICE_SEGMENT]
  if (isAppConstant) {
    const appConst = require('tracking/app/constants')
    return [
      appConst.EVENT_CATEGORY,
      appConst.EVENT_ACTION,
      appConst.COUPON_SCREEN_NAME,
      appConst.TrackingActionType,
    ]
  } else {
    const webConst = require('tracking/web/constants')
    return [
      'coupon',
      webConst.EVENT_ACTION,
      webConst.COUPON_SCREEN_NAME,
      webConst.TrackingActionType,
      webConst.BUTTON_NAME,
    ]
  }
})()

module.exports = {
  EVENT_CATEGORY,
  EVENT_ACTION,
  COUPON_SCREEN_NAME,
  TrackingActionType,
  BUTTON_NAME,
}
