import React, { useEffect } from 'react'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import lottie from 'lottie-web'

const SuccessSnack = ({
  openSnackbar,
  clickSnack,
  closeSnackbar,
  vertical = 'top',
  successText,
}) => {
  const SnackAction = clickSnack ? (
    <ActionText onClick={clickSnack}>立即使用</ActionText>
  ) : (
    ''
  )
  useEffect(() => {
    if (openSnackbar) {
      lottie.loadAnimation({
        container: document.getElementById('snack-icon'),
        renderer: 'svg',
        loop: 0,
        path: `/static/animations/coupon_ic_redeem_success.json`,
      })
    }
  }, [openSnackbar])
  const message = (
    <>
      <SnackIcon id="snack-icon" />
      <Content>{successText}</Content>
    </>
  )
  return (
    <Snackbar
      open={openSnackbar}
      disableWindowBlurListener
      action={SnackAction}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'center', vertical: vertical }}
      message={message}
      onClose={closeSnackbar}
    ></Snackbar>
  )
}

const ActionText = styled.span`
  font-size: 16px;
  color: #fff;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  margin-right: 10px;
`
const SnackIcon = styled.div`
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
`
const Content = styled.span`
  vertical-align: middle;
  font-size: 16px;
  padding-left: 8px;
`
export default SuccessSnack
