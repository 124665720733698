import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useUserAgentStore } from 'store/userAgentStore'

const Spinner = (props) => {
  const userAgentStore = useUserAgentStore()
  const { isDarkMode } = userAgentStore
  const { customcolor } = props
  const defaultColor = isDarkMode ? '#5294ff' : '#0735E5'
  const ColorCircularProgress = withStyles({
    root: {
      color: customcolor || defaultColor,
    },
  })(CircularProgress)
  return <ColorCircularProgress {...props} />
}

export default Spinner
