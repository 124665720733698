import React, { useContext } from 'react'
import { useLocalStore } from 'mobx-react-lite'
import { toJS } from 'mobx'
import config from 'config'
import getConfig from 'next/config'
import * as R from 'ramda'
import jwtDecode from 'jwt-decode'
import useAutorun from 'hooks/useAutorun'
import { v4 as uuidv4 } from 'uuid'
import { useUserStore } from 'store/userStore'
import envUtil from 'utils/envUtil'
import { TrackingActionType, EVENT_CATEGORY, EVENT_ACTION } from 'tracking'
import { getWebJsSDK, getBridgeEventSDK } from 'utils/sdkUtil'

let tracker

const GLOBAL_TRACKING_CONFIG = {
  GA: true,
  Piwik: true,
}
const publicRuntimeConfig = getConfig()?.publicRuntimeConfig

const isAppEnv = {
  app01webview: true,
  generalWeb: false,
}[publicRuntimeConfig?.SERVICE_SEGMENT]

const TrackingStoreContext = React.createContext(null)
const trackerClientOptions = {
  GA: {
    trackingId: [config.TRACK.GA_ID, config.TRACK.GA4_ID],
  },
  Piwik: {
    trackingUrl: isAppEnv
      ? config.TRACK.PIWIK_APP_ENDPOINT
      : config.TRACK.PIWIK_WEB_ENDPOINT,
    siteId: isAppEnv ? config.TRACK.APP_SITE_ID : config.TRACK.WEB_SITE_ID,
    userId: uuidv4(),
    isSPA: true,
  },
  // try to disable webviewbridge to see the result of tracking in 01app
  webviewBridgeEnabled: false,
}

const initTracking = async () => {
  return await getWebJsSDK({ trackerClientOptions }, true)
}

const fireEvent = (label) => (category, action) => {
  if (tracker) {
    if (!envUtil.isProd)
      console.log(JSON.stringify({ category, action, label }, null, 2))

    tracker.fire(GLOBAL_TRACKING_CONFIG, { category, action, label })
  }
}

export const TrackingStoreProvider = ({
  pathname: currentPageName,
  children,
}) => {
  const { accessToken: currentAccessToken } = useUserStore()
  const setClientTrackingAccountId = (latestToken) => {
    if (latestToken) {
      try {
        const { accountId } = jwtDecode(latestToken)
        store.setAccountId(accountId)
      } catch (err) {
        console.log('jwt decode fail: ', err)
      }
    } else {
      store.setAccountId('ANONYMOUS')
    }
  }

  const setDeviceId = async (app, isIn01AppWebview, uuid) => {
    if (isIn01AppWebview) {
      const deviceId = await app.getDeviceId()
      store.setUId(deviceId)
    } else {
      store.setUId(uuid)
    }
  }

  const setAccountId = async (loginResponse) => {
    await store.setAccountId(loginResponse.accountId || 'ANONYMOUS')
  }
  const getLatestAccessToken = async (
    loginResponse,
    auth,
    isIn01AppWebview,
    currentAccessToken
  ) => {
    let latestAccessToken
    const { accessToken: ssoToken } = loginResponse
    if (isIn01AppWebview) {
      /**
       * if accessToken is not the newest token, update it
       * and the problem only in app
       * it's just quick fix
       */
      latestAccessToken = currentAccessToken
      const walletToken = R.path(
        ['wallet', 'token'],
        await auth.getTokens(ssoToken)
      )
      if (currentAccessToken !== walletToken) {
        latestAccessToken = walletToken
      }
      return latestAccessToken
    } else {
      if (ssoToken) {
        /**
         * in case the currentAccessToken is not yet initialized when user logged in.
         * the accessToken from login response is returned.
         */
        latestAccessToken = currentAccessToken || ssoToken
        return latestAccessToken
      } else {
        /**
         * if ssoToken is undefined, which means the user is logged out.
         * so there will be no latestAccessToken, undefined is return
         */
        return latestAccessToken
      }
    }
  }

  useAutorun(async () => {
    const uuid = uuidv4()
    const jsSDK = await initTracking()
    await jsSDK.init()
    const sdk = await getBridgeEventSDK()
    const { isSupportedApp } = sdk
    const { app, auth, trackerClient } = jsSDK
    const isIn01AppWebview = isSupportedApp()
    const { response: loginResponse } = await auth.getLoginStatus()
    await setDeviceId(app, isIn01AppWebview, uuid)
    await setAccountId(loginResponse)
    store.setPageViews(currentPageName)
    const latestAccessToken = await getLatestAccessToken(
      loginResponse,
      auth,
      isIn01AppWebview,
      currentAccessToken
    )
    setClientTrackingAccountId(latestAccessToken, uuid)
    tracker = trackerClient
    tracker && tracker.pageView({ GA: true, Piwik: false })
    store.setIsInited(typeof tracker !== 'undefined')
  })

  const composeTracking = (key, label) => {
    // TODO: put this as util
    const { publicRuntimeConfig } = getConfig()
    const trackApp = {
      app01webview: true,
      generalWeb: false,
    }[publicRuntimeConfig.SERVICE_SEGMENT]
    const EventLabel = R.pipe(R.mergeDeepRight, JSON.stringify)
    const labels = EventLabel(store.getDefaultTrackingPayload(), label || {})
    if (trackApp) {
      return dispatchAppTracking(key, labels)
    } else {
      return dispatchWebTracking(key, labels)
    }
  }
  const store = useLocalStore(() => ({
    pageViews: [],
    accountId: null,
    screenName: null,
    isInited: false,
    uId: null,
    // Pure Piwik Tracking
    emitPiwikPageView: () => {
      tracker && tracker.pageView({ Piwik: true, GA: false })
    },
    trackEvent: (key, label) => {
      try {
        composeTracking(key, label)
      } catch (err) {
        console.log('[track event cause error], action type is :', key)
      }
    },
    setPageViews: (currentPageName) => {
      store.screenName = currentPageName
      store.pageViews = R.append(currentPageName, store.pageViews)
    },
    setUId: (uId) => {
      store.uId = uId
    },
    setAccountId: (accountId) => {
      store.accountId = accountId
    },
    setIsInited: (setValue) => {
      store.isInited = setValue
    },
    getDefaultTrackingPayload: () => ({
      uid: store.uId,
      ...(store.accountId !== 'ANONYMOUS' && { account_id: store.accountId }),
      screen_name: store.screenName,
      // TODO: sequence of actions taken
      page_views: toJS(store.pageViews),
      ts: new Date().valueOf(),
    }),
  }))

  return (
    <TrackingStoreContext.Provider value={store}>
      {children}
    </TrackingStoreContext.Provider>
  )
}

export function useTrackingStore() {
  const store = useContext(TrackingStoreContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('You have forgot to use trackingStore, shame on you.')
  }
  return store
}

export const composeSKUTrackingPayload = (
  sku,
  screenName,
  order,
  _extra = {}
) => {
  const {
    id,
    type,
    subTitle,
    name,
    description,
    isFeature,
    isEnable,
    isVisible,
    displayType,
    redeemQuota,
    couponStrategy,
    expire,
  } = sku
  const { isDisplaySerialNumber, useType, tapToUseTimeout, lowThreshold } =
    couponStrategy
  return {
    sku_id: id,
    name: name,
    sku_type: type,
    sub_title: subTitle,
    description: description,
    is_feature: isFeature,
    is_enable: isEnable,
    is_visible: isVisible,
    display_type: displayType,
    redeem_quota: redeemQuota,
    is_display_serial_number: isDisplaySerialNumber,
    use_type: useType,
    expire_type: expire.type,
    expire_value: expire.value,
    tap_to_use_timeout: tapToUseTimeout,
    low_threshold: lowThreshold || null,
    item_position: order,
    screen_name: screenName,
    ..._extra,
  }
}

export const composeCouponTrackingPayload = (
  coupon,
  screenName,
  order,
  _extra = {}
) => {
  const { sku, id } = coupon
  const {
    id: skuId,
    type,
    name,
    subTitle,
    description,
    isFeature,
    isEnable,
    isVisible,
    displayType,
    redeemQuota,
    couponStrategy,
    expire,
  } = sku
  const { isDisplaySerialNumber, useType, tapToUseTimeout, lowThreshold } =
    couponStrategy
  return {
    sku_id: skuId,
    sku_type: type,
    name: name,
    sub_title: subTitle,
    description: description,
    is_feature: isFeature,
    is_enable: isEnable,
    is_visible: isVisible,
    display_type: displayType,
    redeem_quota: redeemQuota,
    is_display_serial_number: isDisplaySerialNumber,
    use_type: useType,
    expire_type: expire.type,
    expire_value: expire.value,
    tap_to_use_timeout: tapToUseTimeout,
    low_threshold: lowThreshold,
    coupon_id: id,
    item_position: order,
    screen_name: screenName,
    ..._extra,
  }
}

export const dispatchWebTracking = async (action, eventLabel) =>
  fireEvent(eventLabel)(EVENT_CATEGORY, action)

export const dispatchAppTracking = async (trackingKey, eventLabel) => {
  const eventTrack = fireEvent(eventLabel)
  switch (trackingKey) {
    /* listing */
    case TrackingActionType.skus.view:
      return eventTrack(EVENT_CATEGORY.SKUS, EVENT_ACTION.VIEW)
    case TrackingActionType.skus.tap:
      return eventTrack(EVENT_CATEGORY.SKUS, EVENT_ACTION.CLICK_ITEM)
    case TrackingActionType.skus.showBindPhoneModal:
      return eventTrack(
        EVENT_CATEGORY.SKUS,
        EVENT_ACTION.REACH_BIND_PHONE_MODAL
      )
    case TrackingActionType.skus.bindPhoneModalConfirm:
      return eventTrack(
        EVENT_CATEGORY.SKUS,
        EVENT_ACTION.CLICK_BIND_PHONE_MODAL_CONFIRM
      )
    case TrackingActionType.tabs.skuPress:
      return eventTrack(EVENT_CATEGORY.SKUS, EVENT_ACTION.CLICK_TAB)
    case TrackingActionType.tabs.couponPress:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.CLICK_TAB)
    case TrackingActionType.mycoupon.view:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.VIEW)
    case TrackingActionType.mycoupon.tap:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.CLICK_ITEM)
    case TrackingActionType.mycoupon.available:
      return eventTrack(
        EVENT_CATEGORY.COUPONS,
        EVENT_ACTION.CLICK_CHECK_DISABLED_COUPON
      )
    case TrackingActionType.mycoupon.showLogin:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.REACH_LOGIN)
    case TrackingActionType.mycoupon.showBindPhone:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.REACH_BIND_PHONE)
    case TrackingActionType.mycoupon.login:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.CLICK_LOGIN)
    case TrackingActionType.mycoupon.bindPhone:
      return eventTrack(EVENT_CATEGORY.COUPONS, EVENT_ACTION.CLICK_BIND_PHONE)
    /* coupon detail */
    case TrackingActionType.sku.view:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.VIEW)
    case TrackingActionType.coupon.view:
      return eventTrack(EVENT_CATEGORY.COUPON_DETAIL, EVENT_ACTION.VIEW)
    case TrackingActionType.sku.viewMore:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.CLICK_VIEW_MORE)
    case TrackingActionType.sku.cancel:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.CLICK_CANCEL)
    case TrackingActionType.coupon.viewMore:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.CLICK_VIEW_MORE
      )
    case TrackingActionType.coupon.cancel:
      return eventTrack(EVENT_CATEGORY.COUPON_DETAIL, EVENT_ACTION.CLICK_CANCEL)
    /* redeem related */
    case TrackingActionType.redeem.tap:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.CLICK_REDEEM)
    case TrackingActionType.redeem.login:
      return eventTrack(
        EVENT_CATEGORY.SKU_DETAIL,
        EVENT_ACTION.CLICK_REDEEM_LOGIN
      )
    case TrackingActionType.redeem.bindPhone:
      return eventTrack(
        EVENT_CATEGORY.SKU_DETAIL,
        EVENT_ACTION.CLICK_REDEEM_BIND_PHONE
      )
    case TrackingActionType.redeem.confirmModal:
      return eventTrack(
        EVENT_CATEGORY.SKU_DETAIL,
        EVENT_ACTION.REACH_REDEEM_CONFIRM
      )
    case TrackingActionType.redeem.tapConfirm:
      return eventTrack(
        EVENT_CATEGORY.SKU_DETAIL,
        EVENT_ACTION.CLICK_REDEEM_CONFIRM
      )
    case TrackingActionType.redeem.successCallback:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.REDEEM_SUCCEED)
    case TrackingActionType.redeem.failCallback:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.REDEEM_FAILED)
    case TrackingActionType.redeem.again:
      return eventTrack(
        EVENT_CATEGORY.SKU_DETAIL,
        EVENT_ACTION.CLICK_REDEEM_AGAIN
      )
    case TrackingActionType.redeem.checkMyCoupon:
      return eventTrack(EVENT_CATEGORY.SKU_DETAIL, EVENT_ACTION.CLICK_MY_COUPON)
    case TrackingActionType.redeem.tapRetry:
      return eventTrack(
        EVENT_CATEGORY.SKU_DETAIL,
        EVENT_ACTION.CLICK_REDEEM_RETRY
      )
    /* Collect Pin */
    case TrackingActionType.collectPin.firstInput:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.COLLECT_PIN_FIRST_INPUT
      )
    case TrackingActionType.collectPin.submit:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.COLLECT_PIN_SUBMIT
      )
    case TrackingActionType.collectPin.submitSuccess:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.COLLECT_PIN_SUCCEEDED
      )
    case TrackingActionType.collectPin.submitFail:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.COLLECT_PIN_FAILED
      )
    /* my coupon tap to use */
    case TrackingActionType.coupon.tapToUse:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.CLICK_COPY_CODE
      )
    case TrackingActionType.coupon.tapMerchant:
      return eventTrack(EVENT_CATEGORY.COUPON_DETAIL, EVENT_ACTION.CLICK_STORE)
    case TrackingActionType.coupon.codeEnlarge:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.ENLARGE_COUPON
      )
    case TrackingActionType.coupon.showCouponCode:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.CLICK_SHOW_CODE
      )
    case TrackingActionType.coupon.showCouponCodeModal:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.REACH_SHOW_CODE_MODAL
      )
    case TrackingActionType.coupon.couponCodeModalConfirm:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.CLICK_SHOW_CODE_MODAL_CONFIRM
      )
    case TrackingActionType.coupon.showCodeSucceeded:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.SHOW_CODE_SUCCEEDED
      )
    case TrackingActionType.coupon.showCodeFailed:
      return eventTrack(
        EVENT_CATEGORY.COUPON_DETAIL,
        EVENT_ACTION.SHOW_CODE_FAILED
      )
  }
}
