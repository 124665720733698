import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import config from 'config'
import { isSSR, isInStandaloneMode } from 'utils/commonUtil'
import { useTrackingStore } from 'store/trackingStore'
import { EVENT_ACTION, TrackingActionType } from 'tracking'
import Cookie from 'js-cookie'

const WebPush = observer(({ featureFlags }) => {
  const { webPush } = featureFlags
  const { ONE_SIGNAL_APP_ID: oneSignalAppId } = config
  const { trackEvent: track } = useTrackingStore()
  const updateOneSignalUserId = async () => {
    // We have to make sure only fire only once within 30 mins tracking session
    if (!Cookie.get('oneSignalUserId')) {
      const oneSignalUserId = await window.OneSignal.getUserId()
      if (oneSignalUserId) {
        track(EVENT_ACTION.WEB_PUSH, {
          story: TrackingActionType.webPush.updateUserId,
          oneSignalUserId: oneSignalUserId,
        })

        const expireInHalfHour = 1 / 48
        Cookie.set('oneSignalUserId', true, { expires: expireInHalfHour })
      }
    }
  }

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/OneSignalSDKWorker.js')
      })
    }
  })

  useEffect(() => {
    console.group('web push')
    console.log('feature flag: ', webPush)
    console.log('standalone: ', isInStandaloneMode())
    console.groupEnd()
    if (!isSSR && webPush && isInStandaloneMode()) {
      const OneSignal = window.OneSignal
      if (OneSignal) {
        // Init the one signal service
        OneSignal.push(() => {
          OneSignal.init({
            appId: oneSignalAppId,
            allowLocalhostAsSecureOrigin: true,
            notifyButton: {
              enable: false,
            },
            promptOptions: {
              slidedown: {
                enabled: true,
                autoPrompt: true,
                timeDelay: 1,
                actionMessage: '接收《01優惠券》推送，最新最抵著數即刻知！',
                acceptButtonText: '接受',
                cancelButtonText: '我再諗諗',
              },
            },
          })
        })

        // Slide Prompt Events
        OneSignal.push(() => {
          // Slide Prompt Shown to user
          OneSignal.on('popoverShown', () => {
            track(EVENT_ACTION.WEB_PUSH, {
              story: TrackingActionType.webPush.shown,
            })
          })

          // User accept notifications
          OneSignal.on('popoverAllowClick', () => {
            const allowButton = document.getElementById(
              'onesignal-slidedown-allow-button'
            )
            track(EVENT_ACTION.WEB_PUSH, {
              story: TrackingActionType.webPush.accept,
              btn_text: allowButton ? allowButton.textContent : '',
              is_allowed: true,
            })
          })

          // User dismiss/decline to accept notifications
          OneSignal.on('popoverCancelClick', () => {
            const cancelButton = document.getElementById(
              'onesignal-slidedown-cancel-button'
            )

            track(EVENT_ACTION.WEB_PUSH, {
              story: TrackingActionType.webPush.dismiss,
              btn_text: cancelButton ? cancelButton.textContent : '',
              is_allowed: false,
            })
          })

          // Detect if Web Push is enabled
          OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
            if (isEnabled) {
              updateOneSignalUserId().catch((e) => console.error(e))
            }
          })
        })
      }
    }
  })

  return <div></div>
})

export default WebPush
