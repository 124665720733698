import baseColor from '../baseTheme.js'

export const lightModeColor = {
  primary: baseColor.blue200,
  pagebgColor: baseColor.blue20,
  masterTitle: baseColor.dark200,
  subTitle: baseColor.dark100,
  cardBgColor: baseColor.white,
  pointerColor: baseColor.blue200,
  importantColor: baseColor.rosiness200,
  couponBlue: baseColor.blue200,
  buttonBgColor: baseColor.blue200,
  buttonBorderColor: baseColor.blue200,
  buttonColor: baseColor.white,
  borderButtonColor: baseColor.blue200,
  disableButtonColor: baseColor.white,
  disableButtonBgColor: '#a2b5f9',
  bubbleBgColor: baseColor.white,
  modalBgColor: baseColor.white,
  borderColor: baseColor.blue20,
  borderColor2: 'rgba(3,7,15,0.2)',
  headerBgColor: baseColor.white,
  headerTitleColor: baseColor.dark100,
  headerArrowColor: baseColor.dark100,
  inputColor: baseColor.dark200,
  searchInputBgColor: baseColor.grey50,
  categoryItemColor: baseColor.grey600,
  skuTitleColor: baseColor.dark200,
  invalidSkuColor: baseColor.grey400,
  bottomTabBgColor: baseColor.white,
  bottomTabColor: baseColor.grey600,
  alertTitleColor: baseColor.dark100,
  textColor: baseColor.dark100,
  codeDisplayerLabel: baseColor.dark100,
  deadlineLabelColor: baseColor.dark200,

  emptyPage: {
    descriptionColor: baseColor.dark100,
  },
  settingPage: {
    bgColor: baseColor.white,
    color: baseColor.dark200,
    borderColor: baseColor.blue20,
  },
  footerBgColor: baseColor.white,
  footerColor: baseColor.grey400,

  pin: {
    color: baseColor.dark200,
    defaultBorder: baseColor.grey100,
    activeBorder: baseColor.blue200,
    disableBorder: baseColor.grey50,
    disableBg: baseColor.grey50,
    disableColor: baseColor.grey100,
  },

  keyboard: {
    bgColor: baseColor.white,
    borderColor: baseColor.grey80,
    numberColor: baseColor.black,
    dismissBarBgColor: baseColor.grey50,
    dismissBarBorderColor: baseColor.grey100,
  },

  contentLoader: {
    backgroundColor: baseColor.grey50,
    foregroundColor: '#f1f6fb',
  },
  gudie: {
    subTitle: baseColor.dark100,
    benefitSpanColor: baseColor.blue400,
  },
  smartBanner: {
    closeBtn: baseColor.grey400,
  },
  ...baseColor,
}

export const darkModeColor = {
  primary: baseColor.blue90,
  pagebgColor: baseColor.grey1100,
  masterTitle: baseColor.overlay87,
  subTitle: baseColor.overlay87,
  cardBgColor: baseColor.grey900,
  pointerColor: baseColor.blue90,
  importantColor: baseColor.rosiness300,
  couponBlue: baseColor.blue90,
  buttonBgColor: baseColor.blue90,
  buttonBorderColor: baseColor.blue90,
  buttonColor: baseColor.overlay87,
  borderButtonColor: baseColor.blue90,
  disableButtonColor: baseColor.overlay38,
  disableButtonBgColor: baseColor.blue70,
  bubbleBgColor: baseColor.grey700,
  modalBgColor: baseColor.grey700,
  borderColor: baseColor.overlay8,
  borderColor2: baseColor.overlay8,
  headerBgColor: baseColor.grey800,
  headerTitleColor: baseColor.overlay87,
  headerArrowColor: baseColor.overlay87,
  inputColor: baseColor.overlay87,
  searchInputBgColor: baseColor.overlay8,
  categoryItemColor: baseColor.overlay87,
  styleButtonBgColor: baseColor.grey800,
  styleButtonColor: baseColor.blue90,
  styleButtonBorderColor: baseColor.blue90,
  skuTitleColor: baseColor.overlay87,
  invalidSkuColor: baseColor.overlay60,
  bottomTabBgColor: baseColor.grey800,
  bottomTabColor: baseColor.overlay87,

  alertTitleColor: baseColor.overlay87,
  textColor: baseColor.overlay87,
  codeDisplayerLabel: baseColor.dark100,
  deadlineLabelColor: baseColor.overlay87,
  emptyPage: {
    descriptionColor: baseColor.overlay87,
  },

  settingPage: {
    bgColor: baseColor.grey1100,
    color: baseColor.overlay87,
    borderColor: baseColor.overlay8,
  },

  footerBgColor: baseColor.grey1100,
  footerColor: baseColor.overlay87,

  pin: {
    color: baseColor.overlay87,
    defaultBorder: baseColor.overlay38,
    activeBorder: baseColor.blue90,
    disableBorder: baseColor.overlay8,
    disableBg: baseColor.overlay8,
    disableColor: baseColor.overlay38,
  },
  keyboard: {
    bgColor: baseColor.grey700,
    borderColor: baseColor.overlay8,
    numberColor: baseColor.overlay87,
    dismissBarBgColor: baseColor.grey700,
    dismissBarBorderColor: baseColor.overlay8,
  },
  contentLoader: {
    backgroundColor: baseColor.grey1000,
    foregroundColor: baseColor.overlay8,
  },
  gudie: {
    subTitle: baseColor.overlay87,
    benefitSpanColor: baseColor.overlay87,
  },
  smartBanner: {
    closeBtn: baseColor.overlay87,
  },
  ...baseColor,
}
