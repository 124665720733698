module.exports = {
  WEB_SERVER: {
    PORT: 3000,
    BASE_URL: 'https://dev-coupon.hktester.com',
    WEB_BASE_URL: 'https://dev.01coupon.com',
  },
  SSO: {
    APP_ID: 'e2cfa7a8-cc51-40f7-ba2c-53493290bdad',
    WEB_APP_ID: '25b7c1da-e2a0-49f7-8e05-d1ee88234fdf',
    REDIRECT_TO_HOST: 'https://dev-coupon.hktester.com',
    REDIRECT_TO_WEB_HOST: 'https://dev.01coupon.com',
    WEB_SSO_DOMAIN: 'https://staging-sso.01coupon.com/api/v1.0',
  },
  COUPON_API: {
    SERVER_URL: 'https://dev-wallet.hktester.com/coupon-service/',
    TIMEOUT: 30000,
  },
  CAMPAIGN: {
    COOLDOWN: 30, // in seconds
  },
  ONE_SIGNAL_APP_ID: 'b4203b64-cab4-4e58-b24a-5e9272ebf40d',
}
